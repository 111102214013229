.request--quote-container {
  width: 100%;
  display: flex;
  overflow: auto;
  min-height: 100vh;
  align-items: center;
  flex-direction: column;
}
.request--quote-container01 {
  flex: 0 0 auto;
  width: 100%;
  height: auto;
  display: flex;
  padding: var(--dl-space-space-twounits);
  margin-top: 0px;
  align-items: center;
  margin-left: 0px;
  justify-content: flex-start;
}
.request--quote-navlink {
  display: contents;
}
.request--quote-icon {
  width: 24px;
  height: 24px;
  text-decoration: none;
}
.request--quote-text {
  font-size: 16px;
  font-style: normal;
  font-family: Poppins;
  font-weight: 600;
  margin-left: var(--dl-space-space-halfunit);
}
.request--quote-container02 {
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  padding: var(--dl-space-space-unit);
  align-items: center;
  flex-direction: row;
}
.request--quote-container03 {
  flex: 0 0 auto;
  /* width: 8%; */
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
.request--quote-container04 {
  flex: 0 0 auto;
  width: 100%;
  cursor: pointer;
  height: auto;
  display: flex;
  margin-top: var(--dl-space-space-halfunit);
  align-items: flex-start;
  border-color: var(--dl-color-gray-black);
  border-width: 1px;
  justify-content: center;
}
.request--quote-image {
  width: 100px;
  object-fit: cover;
}
.request--quote-container05 {
  flex: 0 0 auto;
  width: 100%;
  cursor: pointer;
  height: auto;
  display: flex;
  margin-top: var(--dl-space-space-halfunit);
  align-items: flex-start;
  border-color: var(--dl-color-gray-black);
  border-width: 1px;
  justify-content: center;
}
.request--quote-image1 {
  width: 100px;
  object-fit: cover;
}
.request--quote-container06 {
  flex: 0 0 auto;
  width: 100%;
  cursor: pointer;
  height: auto;
  display: flex;
  margin-top: var(--dl-space-space-halfunit);
  align-items: flex-start;
  border-color: var(--dl-color-gray-black);
  border-width: 1px;
  justify-content: center;
}
.request--quote-image2 {
  width: 100px;
  object-fit: cover;
}
.request--quote-container07 {
  flex: 0 0 auto;
  width: 40%;
  height: auto;
  display: flex;
  padding: 0px;
  align-items: center;
  justify-content: center;
}
.request--quote-image3 {
  width: 50%;
  height: auto;
  object-fit: cover;
  border-color: var(--dl-color-gray-black);
  border-width: 1px;
}
.request--quote-container08 {
  flex: 0 0 auto;
  width: 52%;
  height: auto;
  display: flex;
  padding: var(--dl-space-space-twounits);
  align-items: flex-start;
  border-radius: 10px;
  flex-direction: column;
}
.request--quote-text01 {
  color: var(--dl-color-gray-black);
  font-size: 20px;
  font-family: Poppins;
}
.request--quote-text02 {
  color: var(--dl-color-gray-black);
  font-size: 30px;
  margin-top: var(--dl-space-space-halfunit);
  font-family: Poppins;
}
.request--quote-text03 {
  color: var(--dl-color-gray-white);
  font-size: 20px;
  font-style: normal;
  margin-top: var(--dl-space-space-halfunit);
  font-family: Poppins;
  font-weight: 500;
  margin-bottom: var(--dl-space-space-halfunit);
  background-color: var(--dl-color-primary-100);
  padding: 5px 10px 5px 10px;
  border-radius: 20px;
}
.request--quote-ul {
  list-style: disc;
}
.request--quote-text04 {
  color: var(--dl-color-gray-black);
  font-family: Poppins;
}
.request--quote-text05 {
  color: var(--dl-color-gray-black);
  font-family: Poppins;
}
.request--quote-container09 {
  flex: 0 0 auto;
  width: 100%;
  height: auto;
  display: flex;
  margin-top: var(--dl-space-space-halfunit);
  align-items: center;
  margin-bottom: var(--dl-space-space-halfunit);
}
.request--quote-textinput {
  width: 120px;
  margin-top: 0px;
  font-family: Poppins;
  margin-right: var(--dl-space-space-unit);
  border-radius: 50px;
  margin-bottom: 0px;
}
.request--quote-button {
  color: var(--dl-color-gray-white);
  cursor: pointer;
  margin-top: var(--dl-space-space-halfunit);
  transition: 0.3s;
  font-family: Poppins;
  padding-top: var(--dl-space-space-halfunit);
  border-width: 0px;
  padding-left: var(--dl-space-space-unit);
  border-radius: 50px;
  margin-bottom: var(--dl-space-space-halfunit);
  padding-right: var(--dl-space-space-unit);
  padding-bottom: var(--dl-space-space-halfunit);
  background-color: var(--dl-color-gray-black);
}
.request--quote-button:hover {
  color: var(--dl-color-gray-white);
  background-color: var(--dl-color-primary-100);
}
.request--quote-container10 {
  flex: 0 0 auto;
  width: 100%;
  display: none;
  padding: var(--dl-space-space-threeunits);
  align-items: center;
  flex-direction: row;
}
.request--quote-container11 {
  flex: 0 0 auto;
  width: 30%;
  height: auto;
  display: flex;
  padding: 0px;
  align-items: center;
  justify-content: center;
}
.request--quote-image4 {
  width: 100%;
  height: auto;
  object-fit: cover;
}
.request--quote-container12 {
  flex: 0 0 auto;
  width: 8%;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
.request--quote-container13 {
  flex: 0 0 auto;
  width: 100%;
  cursor: pointer;
  height: auto;
  display: flex;
  margin-top: var(--dl-space-space-halfunit);
  align-items: flex-start;
  border-color: var(--dl-color-gray-black);
  border-width: 1px;
  justify-content: center;
}
.request--quote-image5 {
  width: 100px;
  object-fit: cover;
}
.request--quote-container14 {
  flex: 0 0 auto;
  width: 100%;
  cursor: pointer;
  height: auto;
  display: flex;
  margin-top: var(--dl-space-space-halfunit);
  align-items: flex-start;
  border-color: var(--dl-color-gray-black);
  border-width: 1px;
  justify-content: center;
}
.request--quote-image6 {
  width: 100px;
  object-fit: cover;
}
.request--quote-container15 {
  flex: 0 0 auto;
  width: 100%;
  cursor: pointer;
  height: auto;
  display: flex;
  margin-top: var(--dl-space-space-halfunit);
  align-items: flex-start;
  border-color: var(--dl-color-gray-black);
  border-width: 1px;
  justify-content: center;
}
.request--quote-image7 {
  width: 100px;
  object-fit: cover;
}
.request--quote-container16 {
  flex: 0 0 auto;
  width: 70%;
  height: auto;
  display: flex;
  padding: var(--dl-space-space-twounits);
  align-items: flex-start;
  border-radius: 10px;
  flex-direction: column;
}
.request--quote-text06 {
  color: var(--dl-color-gray-white);
  font-size: 20px;
}
.request--quote-text07 {
  color: var(--dl-color-gray-white);
  font-size: 30px;
  margin-top: var(--dl-space-space-halfunit);
}
.request--quote-text08 {
  color: var(--dl-color-gray-white);
  font-size: 25px;
  font-style: normal;
  margin-top: var(--dl-space-space-halfunit);
  font-weight: 600;
  margin-bottom: var(--dl-space-space-halfunit);
}
.request--quote-ul1 {
  list-style: disc;
}
.request--quote-text09 {
  color: var(--dl-color-gray-white);
}
.request--quote-text10 {
  color: var(--dl-color-gray-white);
}
.request--quote-container17 {
  flex: 0 0 auto;
  width: 100%;
  height: 100px;
  display: flex;
  align-items: flex-start;
}
.request--quote-textinput1 {
  width: 150px;
  margin-top: 0px;
  font-family: Poppins;
  margin-right: var(--dl-space-space-unit);
  border-radius: 50px;
  margin-bottom: 0px;
}
.request--quote-button1 {
  cursor: pointer;
  margin-top: var(--dl-space-space-unit);
  transition: 0.3s;
  padding-top: var(--dl-space-space-halfunit);
  border-width: 0px;
  padding-left: var(--dl-space-space-unit);
  border-radius: 50px;
  padding-right: var(--dl-space-space-unit);
  padding-bottom: var(--dl-space-space-halfunit);
}
.request--quote-button1:hover {
  color: var(--dl-color-gray-white);
  background-color: var(--dl-color-primary-100);
}
@media(max-width: 1600px) {
  .request--quote-container01 {
    padding: var(--dl-space-space-unit);
    margin-top: var(--dl-space-space-unit);
  }
  .request--quote-text {
    margin-left: var(--dl-space-space-halfunit);
  }
  .request--quote-container02 {
    justify-content: space-between;
  }
  .request--quote-container07 {
    width: 30%;
  }
  .request--quote-image3 {
    width: 100%;
  }
  .request--quote-container08 {
    width: 52%;
    padding: var(--dl-space-space-halfunit);
  }
  .request--quote-text01 {
    color: var(--dl-color-gray-black);
    font-size: 20px;
  }
  .request--quote-text02 {
    color: var(--dl-color-gray-black);
    font-size: 28px;
    margin-top: var(--dl-space-space-halfunit);
  }
  .request--quote-text03 {
    color: var(--dl-color-gray-white);
    font-size: 18px;
  }
  .request--quote-text04 {
    color: var(--dl-color-gray-black);
  }
  .request--quote-text05 {
    color: var(--dl-color-gray-black);
  }
  .request--quote-button {
    color: var(--dl-color-gray-white);
    background-color: var(--dl-color-gray-black);
  }
  .request--quote-image4 {
    width: 100%;
  }
  .request--quote-container16 {
    width: 70%;
  }
  .request--quote-text06 {
    font-size: 20px;
  }
  .request--quote-text07 {
    font-size: 28px;
    margin-top: var(--dl-space-space-halfunit);
  }
  .request--quote-text08 {
    font-size: 20px;
  }
}
@media(max-width: 1200px) {
  .request--quote-text {
    margin-left: var(--dl-space-space-halfunit);
  }
  .request--quote-image {
    width: 80px;
  }
  .request--quote-image1 {
    width: 80px;
  }
  .request--quote-image2 {
    width: 80px;
  }
  .request--quote-text01 {
    font-size: 18px;
  }
  .request--quote-text02 {
    font-size: 25px;
  }
  .request--quote-text03 {
    font-size: 16px;
  }
  .request--quote-textinput {
    width: 120px;
    font-size: 12px;
  }
  .request--quote-button {
    font-size: 12px;
  }
  .request--quote-image5 {
    width: 80px;
  }
  .request--quote-image6 {
    width: 80px;
  }
  .request--quote-image7 {
    width: 80px;
  }
  .request--quote-text06 {
    font-size: 18px;
  }
  .request--quote-text07 {
    font-size: 25px;
  }
  .request--quote-text08 {
    font-size: 18px;
  }
  .request--quote-textinput1 {
    font-size: 12px;
  }
}
@media(max-width: 991px) {
  .request--quote-icon {
    width: 20px;
  }
  .request--quote-text {
    font-size: 14px;
    margin-left: var(--dl-space-space-halfunit);
  }
  .request--quote-container02 {
    padding: var(--dl-space-space-unit);
  }
  .request--quote-image {
    width: 60px;
  }
  .request--quote-image1 {
    width: 60px;
  }
  .request--quote-image2 {
    width: 60px;
  }
  .request--quote-text02 {
    font-size: 22px;
  }
  .request--quote-text03 {
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
  }
  .request--quote-image5 {
    width: 60px;
  }
  .request--quote-image6 {
    width: 60px;
  }
  .request--quote-image7 {
    width: 60px;
  }
  .request--quote-text07 {
    font-size: 22px;
  }
}
@media(max-width: 767px) {
  .request--quote-container01 {
    align-items: center;
  }
  .request--quote-text {
    font-size: 12px;
    margin-left: var(--dl-space-space-halfunit);
  }
  .request--quote-container02 {
    display: none;
  }
  .request--quote-container10 {
    display: flex;
    padding: var(--dl-space-space-unit);
    flex-direction: column;
  }
  .request--quote-container11 {
    width: 100%;
  }
  .request--quote-image4 {
    width: 350px;
    height: 100%;
    border-color: var(--dl-color-gray-black);
    border-width: 1px;
    margin-bottom: 0px;
  }
  .request--quote-container12 {
    width: auto;
    height: auto;
    flex-direction: row;
  }
  .request--quote-container13 {
    width: auto;
    cursor: pointer;
    margin-left: var(--dl-space-space-halfunit);
    margin-right: var(--dl-space-space-halfunit);
  }
  .request--quote-image5 {
    width: 80px;
  }
  .request--quote-container14 {
    width: auto;
    cursor: pointer;
    margin-left: var(--dl-space-space-halfunit);
    margin-right: var(--dl-space-space-halfunit);
  }
  .request--quote-image6 {
    width: 80px;
  }
  .request--quote-container15 {
    width: auto;
    cursor: pointer;
    margin-left: var(--dl-space-space-halfunit);
    margin-right: var(--dl-space-space-halfunit);
  }
  .request--quote-image7 {
    width: 80px;
  }
  .request--quote-container16 {
    width: 100%;
    padding: var(--dl-space-space-unit);
  }
  .request--quote-text06 {
    color: var(--dl-color-gray-black);
    font-size: 16px;
    font-family: Poppins;
  }
  .request--quote-text07 {
    color: var(--dl-color-gray-black);
    font-size: 18px;
    font-family: Poppins;
  }
  .request--quote-text08 {
    color: var(--dl-color-gray-white);
    font-size: 14px;
    font-style: normal;
    font-family: Poppins;
    font-weight: 500;
    background-color: var(--dl-color-primary-100);
    padding: 2px 10px 2px 10px;
    border-radius: 20px;

  }
  .request--quote-text09 {
    color: var(--dl-color-gray-black);
    font-family: Poppins;
  }
  .request--quote-text10 {
    color: var(--dl-color-gray-black);
    font-family: Poppins;
  }
  .request--quote-container17 {
    height: auto;
    margin-top: var(--dl-space-space-unit);
    align-items: center;
    justify-content: center;
  }
  .request--quote-textinput1 {
    width: 120px;
    margin-left: var(--dl-space-space-halfunit);
    margin-right: var(--dl-space-space-halfunit);
  }
  .request--quote-button1 {
    color: var(--dl-color-gray-white);
    font-size: 14px;
    align-self: center;
    margin-top: 0px;
    font-family: Poppins;
    margin-left: var(--dl-space-space-halfunit);
    margin-right: var(--dl-space-space-halfunit);
    background-color: var(--dl-color-gray-black);
  }
}
@media(max-width: 479px) {
  .request--quote-container01 {
    padding: var(--dl-space-space-halfunit);
  }
  .request--quote-icon {
    width: 15px;
  }
  .request--quote-text {
    font-size: 10px;
  }
  .request--quote-image4 {
    width: 280px;
  }
  .request--quote-image5 {
    width: 50px;
  }
  .request--quote-image6 {
    width: 50px;
  }
  .request--quote-image7 {
    width: 50px;
  }
  .request--quote-container16 {
    padding: 0px;
    margin-top: var(--dl-space-space-unit);
  }
  .request--quote-text06 {
    font-size: 14px;
  }
  .request--quote-text08 {
    font-size: 12px;
  }
  .request--quote-text09 {
    font-size: 12px;
  }
  .request--quote-text10 {
    font-size: 12px;
  }
  .request--quote-textinput1 {
    width: 120px;
  }
  .request--quote-button1 {
    font-size: 12px;
  }
}
table {
  border: 1px solid #ccc;
  border-collapse: collapse;
  margin: 0;
  padding: 0;
  width: 100%;
  table-layout: fixed;
}

table caption {
  font-size: 1.5em;
  margin: .5em 0 .75em;
}

table tr {
  background-color: #f8f8f8;
  border: 1px solid #ddd;
  padding: .35em;
}

table th,
table td {
  padding: .625em;
  text-align: center;
}

table th {
  font-size: .85em;
  letter-spacing: .1em;
  text-transform: uppercase;
}

@media screen and (max-width: 600px) {
  table {
    border: 0;
  }

  table caption {
    font-size: 1.3em;
  }
  
  table thead {
    border: none;
    clip: rect(0 0 0 0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px;
  }
  
  table tr {
    border-bottom: 3px solid #ddd;
    display: block;
    margin-bottom: .625em;
  }
  
  table td {
    border-bottom: 1px solid #ddd;
    display: block;
    font-size: .8em;
    text-align: right;
  }
  
  table td::before {
    /*
    * aria-label has no advantage, it won't be read inside a table
    content: attr(aria-label);
    */
    content: attr(data-label);
    float: left;
    font-weight: bold;
    text-transform: uppercase;
  }
  
  table td:last-child {
    border-bottom: 0;
  }
}

/* general styling */
body {
  font-family: "poppins";
  line-height: 1.25;
}