.careers-container {
  width: 100%;
  display: flex;
  overflow: auto;
  min-height: 100vh;
  align-items: center;
  flex-direction: column;
  background-image: linear-gradient(90deg, rgb(210, 235, 215) 0.00%,rgb(195, 227, 224) 100.00%);
}
.careers-container01 {
  flex: 0 0 auto;
  width: 100%;
  height: auto;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
.careers-container02 {
  flex: 0 0 auto;
  width: 100%;
  height: 100px;
  display: flex;
  align-items: flex-start;
  justify-content: center;
}
.careers-text {
  font-size: 41px;
  font-style: normal;
  margin-top: var(--dl-space-space-twounits);
  text-align: center;
  font-family: Poppins;
  font-weight: 700;
}
.careers-blog {
  width: 100%;
  display: flex;
  max-width: 1600px;
  align-items: center;
  padding-top: 0px;
  padding-left: var(--dl-space-space-threeunits);
  padding-right: var(--dl-space-space-threeunits);
  padding-bottom: var(--dl-space-space-threeunits);
  justify-content: space-between;
}
.careers-container03 {
  display: flex;
  box-shadow: 0px 0px 10px 0px #d4d4d4;
  align-items: center;
  margin-right: var(--dl-space-space-unit);
  justify-content: space-between;
}
.careers-blog-post-card {
  width: 100%;
  display: flex;
  overflow: hidden;
  max-width: var(--dl-size-size-maxwidth);
  box-shadow: 5px 5px 10px 0px rgba(18, 18, 18, 0.1);
  transition: 0.3s;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
.careers-blog-post-card:hover {
  transform: scale(1.02);
}
.careers-image {
  width: 100%;
  height: auto;
  object-fit: cover;
}
.careers-container04 {
  width: 100%;
  display: flex;
  padding: var(--dl-space-space-twounits);
  align-items: flex-start;
  flex-direction: column;
  justify-content: flex-start;
  background-image: linear-gradient(90deg, rgb(88, 181, 107) 0.00%,rgb(31, 149, 141) 100.00%);
}
.careers-text1 {
  color: var(--dl-color-gray-white);
  font-size: 24px;
  font-style: normal;
  font-family: Poppins;
  font-weight: 600;
  margin-bottom: var(--dl-space-space-halfunit);
}
.careers-container05 {
  width: 100%;
  display: flex;
  align-items: center;
  padding-top: 0px;
  flex-direction: row;
  justify-content: flex-start;
}
.careers-link {
  color: var(--dl-color-gray-white);
  cursor: pointer;
  font-size: 12px;
  font-style: normal;
  font-family: Poppins;
  font-weight: 700;
  border-width: 0px;
  padding-left: 0px;
  padding-right: var(--dl-space-space-halfunit);
  text-decoration: none;
  background-color: transparent;
}
.careers-link01 {
  display: contents;
}
.careers-icon {
  fill: var(--dl-color-gray-white);
  width: 20px;
  height: 20px;
  text-decoration: none;
}
.careers-container06 {
  display: flex;
  align-items: center;
  margin-right: var(--dl-space-space-unit);
  justify-content: space-between;
}
.careers-blog-post-card1 {
  width: 100%;
  display: flex;
  overflow: hidden;
  max-width: var(--dl-size-size-maxwidth);
  box-shadow: 5px 5px 10px 0px rgba(18, 18, 18, 0.1);
  transition: 0.3s;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
.careers-blog-post-card1:hover {
  transform: scale(1.02);
}
.careers-image1 {
  width: 100%;
  height: auto;
  object-fit: cover;
}
.careers-container07 {
  width: 100%;
  display: flex;
  padding: var(--dl-space-space-twounits);
  align-items: flex-start;
  flex-direction: column;
  justify-content: flex-start;
  background-image: linear-gradient(90deg, rgb(88, 181, 107) 0.00%,rgb(31, 149, 141) 100.00%);
}
.careers-text2 {
  color: var(--dl-color-gray-white);
  font-size: 24px;
  font-style: normal;
  font-family: Poppins;
  font-weight: 600;
  margin-bottom: var(--dl-space-space-halfunit);
}
.careers-container08 {
  width: 100%;
  display: flex;
  align-items: center;
  padding-top: 0px;
  flex-direction: row;
  justify-content: flex-start;
}
.careers-link02 {
  color: var(--dl-color-gray-white);
  cursor: pointer;
  font-size: 12px;
  font-style: normal;
  font-family: Poppins;
  font-weight: 700;
  border-width: 0px;
  padding-left: 0px;
  padding-right: var(--dl-space-space-halfunit);
  text-decoration: none;
  background-color: transparent;
}
.careers-link03 {
  display: contents;
}
.careers-icon02 {
  fill: var(--dl-color-gray-white);
  width: 20px;
  height: 20px;
  text-decoration: none;
}
.careers-container09 {
  display: flex;
  align-items: center;
  margin-right: var(--dl-space-space-unit);
  justify-content: space-between;
}
.careers-blog-post-card2 {
  width: 100%;
  display: flex;
  overflow: hidden;
  max-width: var(--dl-size-size-maxwidth);
  box-shadow: 5px 5px 10px 0px rgba(18, 18, 18, 0.1);
  transition: 0.3s;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
.careers-blog-post-card2:hover {
  transform: scale(1.02);
}
.careers-image2 {
  width: 100%;
  height: auto;
  object-fit: cover;
}
.careers-container10 {
  width: 100%;
  display: flex;
  padding: var(--dl-space-space-twounits);
  align-items: flex-start;
  flex-direction: column;
  justify-content: flex-start;
  background-image: linear-gradient(90deg, rgb(88, 181, 107) 0.00%,rgb(31, 149, 141) 100.00%);
}
.careers-text3 {
  color: var(--dl-color-gray-white);
  font-size: 24px;
  font-style: normal;
  font-family: Poppins;
  font-weight: 600;
  margin-bottom: var(--dl-space-space-halfunit);
}
.careers-container11 {
  width: 100%;
  display: flex;
  align-items: center;
  padding-top: 0px;
  flex-direction: row;
  justify-content: flex-start;
}
.careers-link04 {
  color: var(--dl-color-gray-white);
  cursor: pointer;
  font-size: 12px;
  font-style: normal;
  font-family: Poppins;
  font-weight: 700;
  border-width: 0px;
  padding-left: 0px;
  padding-right: var(--dl-space-space-halfunit);
  text-decoration: none;
  background-color: transparent;
}
.careers-link05 {
  display: contents;
}
.careers-icon04 {
  fill: var(--dl-color-gray-white);
  width: 20px;
  height: 20px;
  text-decoration: none;
}
.careers-container12 {
  display: flex;
  align-items: center;
  margin-right: 0px;
  justify-content: space-between;
}
.careers-blog-post-card3 {
  width: 100%;
  display: flex;
  overflow: hidden;
  max-width: var(--dl-size-size-maxwidth);
  box-shadow: 5px 5px 10px 0px rgba(18, 18, 18, 0.1);
  transition: 0.3s;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
.careers-blog-post-card3:hover {
  transform: scale(1.02);
}
.careers-image3 {
  width: 100%;
  height: auto;
  object-fit: cover;
}
.careers-container13 {
  width: 100%;
  display: flex;
  padding: var(--dl-space-space-twounits);
  align-items: flex-start;
  flex-direction: column;
  justify-content: flex-start;
  background-image: linear-gradient(90deg, rgb(88, 181, 107) 0.00%,rgb(31, 149, 141) 100.00%);
}
.careers-text4 {
  color: var(--dl-color-gray-white);
  font-size: 24px;
  font-style: normal;
  font-family: Poppins;
  font-weight: 600;
  margin-bottom: var(--dl-space-space-halfunit);
}
.careers-container14 {
  width: 100%;
  display: flex;
  align-items: center;
  padding-top: 0px;
  flex-direction: row;
  justify-content: flex-start;
}
.careers-link06 {
  color: var(--dl-color-gray-white);
  cursor: pointer;
  font-size: 12px;
  font-style: normal;
  font-family: Poppins;
  font-weight: 700;
  border-width: 0px;
  padding-left: 0px;
  padding-right: var(--dl-space-space-halfunit);
  text-decoration: none;
  background-color: transparent;
}
.careers-link07 {
  display: contents;
}
.careers-icon06 {
  fill: var(--dl-color-gray-white);
  width: 20px;
  height: 20px;
  text-decoration: none;
}
.careers-blog1 {
  width: 100%;
  display: flex;
  max-width: 1600px;
  align-items: center;
  padding-top: 0px;
  padding-left: var(--dl-space-space-threeunits);
  padding-right: var(--dl-space-space-threeunits);
  padding-bottom: var(--dl-space-space-threeunits);
  justify-content: space-between;
}
.careers-container15 {
  display: flex;
  align-items: center;
  margin-right: 0px;
  justify-content: space-between;
  width: 25%;
}
.careers-blog-post-card4 {
  width: 100%;
  display: flex;
  overflow: hidden;
  max-width: var(--dl-size-size-maxwidth);
  box-shadow: 5px 5px 10px 0px rgba(18, 18, 18, 0.1);
  transition: 0.3s;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
.careers-blog-post-card4:hover {
  transform: scale(1.02);
}
.careers-image4 {
  width: 100%;
  height: auto;
  object-fit: cover;
}
.careers-container16 {
  width: 100%;
  display: flex;
  padding: var(--dl-space-space-twounits);
  align-items: flex-start;
  flex-direction: column;
  justify-content: flex-start;
  background-image: linear-gradient(90deg, rgb(88, 181, 107) 0.00%,rgb(31, 149, 141) 100.00%);
}
.careers-text5 {
  color: var(--dl-color-gray-white);
  font-size: 24px;
  font-style: normal;
  font-family: Poppins;
  font-weight: 600;
  margin-bottom: var(--dl-space-space-halfunit);
}
.careers-container17 {
  width: 100%;
  display: flex;
  align-items: center;
  padding-top: 0px;
  flex-direction: row;
  justify-content: flex-start;
}
.careers-link08 {
  color: var(--dl-color-gray-white);
  cursor: pointer;
  font-size: 12px;
  font-style: normal;
  font-family: Poppins;
  font-weight: 700;
  border-width: 0px;
  padding-left: 0px;
  padding-right: var(--dl-space-space-halfunit);
  text-decoration: none;
  background-color: transparent;
}
.careers-link09 {
  display: contents;
}
.careers-icon08 {
  fill: var(--dl-color-gray-white);
  width: 20px;
  height: 20px;
  text-decoration: none;
}
.careers-container18 {
  flex: 0 0 auto;
  width: 100%;
  height: auto;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  background-size: cover;
  background-image: url("../../../public/playground_assets/group-99-1500w.jpg");
  background-repeat: no-repeat;
}
.careers-container19 {
  flex: 0 0 auto;
  width: 100%;
  height: auto;
  display: flex;
  margin-top: var(--dl-space-space-twounits);
  align-items: flex-start;
  margin-bottom: var(--dl-space-space-twounits);
  justify-content: center;
}
.careers-text6 {
  color: var(--dl-color-gray-white);
  font-size: 41px;
  font-family: Poppins;
}
.careers-form {
  width: 100%;
  height: auto;
  display: flex;
  padding: 25px;
  max-width: 800px;
  align-self: center;
  box-shadow: 5px 5px 10px 0px #4a4a4a;
  margin-top: 0px;
  border-radius: 30px;
  margin-bottom: var(--dl-space-space-threeunits);
  flex-direction: row;
  background-color: rgba(255, 255, 255, 0.41);
}
.careers-container20 {
  flex: 0 0 auto;
  width: 100%;
  height: auto;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
.careers-container21 {
  flex: 0 0 auto;
  width: 100%;
  height: auto;
  display: flex;
  align-items: flex-start;
  justify-content: center;
}
.careers-container22 {
  flex: 0 0 auto;
  width: 50%;
  height: auto;
  display: flex;
  align-items: center;
  padding-top: var(--dl-space-space-unit);
  padding-left: var(--dl-space-space-unit);
  margin-bottom: var(--dl-space-space-twounits);
  padding-right: var(--dl-space-space-unit);
  flex-direction: column;
  padding-bottom: 0px;
}
.careers-textinput {
  width: 100%;
  height: 50px;
  font-family: Poppins;
  border-width: 0px;
  border-radius: 10px;
  margin-bottom: var(--dl-space-space-twounits);
}
.careers-textinput1 {
  width: 100%;
  height: 50px;
  font-family: Poppins;
  border-width: 0px;
  border-radius: 10px;
  margin-bottom: 0px;
}
.careers-container23 {
  flex: 0 0 auto;
  width: 50%;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.careers-container24 {
  flex: 0 0 auto;
  width: 100%;
  height: auto;
  display: flex;
  padding: var(--dl-space-space-unit);
  align-items: center;
  flex-direction: column;
}
.careers-textinput2 {
  width: 100%;
  height: 50px;
  font-family: Poppins;
  border-width: 0px;
  border-radius: 10px;
  margin-bottom: var(--dl-space-space-twounits);
}
.careers-textinput3 {
  width: 100%;
  height: 50px;
  font-family: Poppins;
  border-width: 0px;
  border-radius: 10px;
  margin-bottom: 0px;
}
.careers-container25 {
  flex: 0 0 auto;
  width: 100%;
  height: auto;
  display: flex;
  margin-top: 0px;
  align-items: center;
  padding-top: 0px;
  padding-left: var(--dl-space-space-unit);
  padding-right: var(--dl-space-space-unit);
  flex-direction: column;
  padding-bottom: var(--dl-space-space-unit);
}
.careers-select {
  width: 100%;
  height: 50px;
  align-self: stretch;
  font-family: sans-serif;
  padding-top: 0px;
  padding-left: var(--dl-space-space-unit);
  border-radius: var(--dl-radius-radius-radius4);
  margin-bottom: var(--dl-space-space-twounits);
  padding-right: var(--dl-space-space-unit);
  padding-bottom: 0px;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
}
.careers-textarea {
  width: 100%;
  height: 136px;
  resize: none;
  border-width: 0px;
  border-radius: 10px;
  margin-bottom: var(--dl-space-space-threeunits);
}
.careers-button {
  color: var(--dl-color-gray-white);
  cursor: pointer;
  font-size: 20px;
  font-style: normal;
  transition: 0.3s;
  font-family: Montserrat;
  font-weight: 700;
  padding-top: var(--dl-space-space-unit);
  border-width: 0px;
  padding-left: var(--dl-space-space-threeunits);
  border-radius: 10px;
  padding-right: var(--dl-space-space-threeunits);
  padding-bottom: var(--dl-space-space-unit);
  background-image: linear-gradient(90deg, rgb(88, 181, 107) 0.00%,rgb(31, 149, 141) 100.00%);
}
.careers-button:hover {
  background-image: linear-gradient(90deg, rgb(0, 0, 0) 0.00%,rgb(0, 0, 0) 100.00%),linear-gradient(90deg, rgb(88, 181, 107) 0.00%,rgb(31, 149, 141) 100.00%);
}
@media(max-width: 1600px) {
  .careers-container01 {
    background-image: linear-gradient(270deg, rgb(210, 235, 215) 0.00%,rgb(195, 227, 224) 100.00%);
  }
  .careers-container04 {
    padding-left: var(--dl-space-space-halfunit);
    padding-right: var(--dl-space-space-halfunit);
    background-image: linear-gradient(90deg, rgb(88, 181, 107) 0.00%,rgb(31, 149, 141) 100.00%);
  }
  .careers-text1 {
    color: var(--dl-color-gray-white);
  }
  .careers-link {
    color: var(--dl-color-gray-white);
  }
  .careers-container07 {
    padding-left: var(--dl-space-space-halfunit);
    padding-right: var(--dl-space-space-halfunit);
    background-image: linear-gradient(90deg, rgb(88, 181, 107) 0.00%,rgb(31, 149, 141) 100.00%);
  }
  .careers-text2 {
    color: var(--dl-color-gray-white);
  }
  .careers-link02 {
    color: var(--dl-color-gray-white);
  }
  .careers-icon02 {
    fill: var(--dl-color-gray-white);
  }
  .careers-container10 {
    padding-left: var(--dl-space-space-halfunit);
    background-image: linear-gradient(90deg, rgb(88, 181, 107) 0.00%,rgb(31, 149, 141) 100.00%);
  }
  .careers-text3 {
    color: var(--dl-color-gray-white);
  }
  .careers-link04 {
    color: var(--dl-color-gray-white);
  }
  .careers-icon04 {
    fill: var(--dl-color-gray-white);
  }
  .careers-container13 {
    background-image: linear-gradient(90deg, rgb(88, 181, 107) 0.00%,rgb(31, 149, 141) 100.00%);
  }
  .careers-text4 {
    color: var(--dl-color-gray-white);
  }
  .careers-link06 {
    color: var(--dl-color-gray-white);
  }
  .careers-icon06 {
    fill: var(--dl-color-gray-white);
  }
  .careers-container16 {
    background-image: linear-gradient(90deg, rgb(88, 181, 107) 0.00%,rgb(31, 149, 141) 100.00%);
  }
  .careers-text5 {
    color: var(--dl-color-gray-white);
  }
  .careers-link08 {
    color: var(--dl-color-gray-white);
  }
  .careers-icon08 {
    fill: var(--dl-color-gray-white);
  }
  .careers-button {
    font-size: 18px;
  }
}
@media(max-width: 1200px) {
  .careers-container03 {
    margin-right: var(--dl-space-space-twounits);
  }
  .careers-container04 {
    padding-left: var(--dl-space-space-halfunit);
    padding-right: var(--dl-space-space-halfunit);
  }
  .careers-text1 {
    font-size: 20px;
    text-align: left;
  }
  .careers-container06 {
    margin-right: var(--dl-space-space-twounits);
  }
  .careers-text2 {
    font-size: 20px;
  }
  .careers-container09 {
    margin-right: var(--dl-space-space-twounits);
  }
  .careers-container10 {
    padding-left: var(--dl-space-space-halfunit);
    padding-right: var(--dl-space-space-halfunit);
  }
  .careers-text3 {
    font-size: 20px;
    text-align: left;
  }
  .careers-container13 {
    padding-left: var(--dl-space-space-halfunit);
    padding-right: var(--dl-space-space-halfunit);
  }
  .careers-text4 {
    font-size: 20px;
  }
  .careers-container15 {
    width: 255px;
    margin-right: 0px;
  }
  .careers-container16 {
    padding-left: var(--dl-space-space-halfunit);
    padding-right: var(--dl-space-space-halfunit);
  }
  .careers-text5 {
    font-size: 20px;
  }
  .careers-container21 {
    height: auto;
  }
  .careers-container22 {
    margin-bottom: 0px;
  }
  .careers-textinput1 {
    margin-bottom: var(--dl-space-space-unit);
  }
  .careers-container23 {
    width: 50%;
    height: auto;
  }
  .careers-container24 {
    width: 100%;
  }
  .careers-textinput3 {
    margin-bottom: var(--dl-space-space-unit);
  }
  .careers-select {
    margin-bottom: var(--dl-space-space-twounits);
  }
  .careers-button {
    font-size: 18px;
  }
}
@media(max-width: 991px) {
  .careers-text {
    font-size: 32px;
  }
  .careers-blog {
    flex-direction: column;
    padding-bottom: 0px;
  }
  .careers-container03 {
    width: 80%;
    margin-right: 0px;
    margin-bottom: var(--dl-space-space-threeunits);
  }
  .careers-image {
    height: 350px;
  }
  .careers-container04 {
    align-items: center;
  }
  .careers-container05 {
    justify-content: center;
  }
  .careers-container06 {
    width: 80%;
    margin-right: 0px;
    margin-bottom: var(--dl-space-space-threeunits);
  }
  .careers-image1 {
    height: 350px;
  }
  .careers-container07 {
    align-items: center;
  }
  .careers-container08 {
    justify-content: center;
  }
  .careers-container09 {
    width: 80%;
    margin-right: 0px;
    margin-bottom: var(--dl-space-space-threeunits);
  }
  .careers-image2 {
    height: 350px;
  }
  .careers-container10 {
    align-items: center;
  }
  .careers-container11 {
    justify-content: center;
  }
  .careers-container12 {
    width: 80%;
    margin-right: 0px;
    margin-bottom: var(--dl-space-space-threeunits);
  }
  .careers-blog-post-card3 {
    align-items: center;
  }
  .careers-image3 {
    height: 350px;
  }
  .careers-container13 {
    align-items: center;
  }
  .careers-container14 {
    justify-content: center;
  }
  .careers-blog1 {
    align-items: center;
    flex-direction: column;
  }
  .careers-container15 {
    width: 80%;
    margin-right: 0px;
    margin-bottom: var(--dl-space-space-threeunits);
  }
  .careers-image4 {
    height: 350px;
  }
  .careers-container16 {
    align-items: center;
  }
  .careers-container17 {
    justify-content: center;
  }
  .careers-button {
    font-size: 18px;
  }
}
@media(max-width: 767px) {
  .careers-blog {
    padding-left: var(--dl-space-space-twounits);
    padding-right: var(--dl-space-space-twounits);
  }
  .careers-container03 {
    width: 90%;
  }
  .careers-container04 {
    align-items: center;
  }
  .careers-container05 {
    justify-content: center;
  }
  .careers-container06 {
    width: 90%;
  }
  .careers-container07 {
    align-items: center;
  }
  .careers-container08 {
    justify-content: center;
  }
  .careers-container09 {
    width: 90%;
  }
  .careers-container10 {
    align-items: center;
  }
  .careers-container11 {
    justify-content: center;
  }
  .careers-container12 {
    width: 90%;
  }
  .careers-container13 {
    align-items: center;
  }
  .careers-container14 {
    justify-content: center;
  }
  .careers-blog1 {
    padding-left: var(--dl-space-space-twounits);
    padding-right: var(--dl-space-space-twounits);
    padding-bottom: 0px;
  }
  .careers-container15 {
    width: 90%;
  }
  .careers-container16 {
    align-items: center;
  }
  .careers-container17 {
    justify-content: center;
  }
  .careers-container21 {
    flex-direction: column;
  }
  .careers-container22 {
    width: 100%;
    align-items: center;
  }
  .careers-container23 {
    width: 100%;
  }
  .careers-container24 {
    width: 100%;
    display: block;
    padding-top: var(--dl-space-space-halfunit);
    margin-bottom: var(--dl-space-space-twounits);
    padding-bottom: 0px;
  }
  .careers-textinput3 {
    margin-bottom: 0px;
  }
  .careers-select {
    margin-bottom: var(--dl-space-space-twounits);
  }
  .careers-button {
    font-size: 18px;
  }
}
@media(max-width: 479px) {
  .careers-blog {
    padding-top: 0px;
    padding-left: var(--dl-space-space-unit);
    padding-right: var(--dl-space-space-unit);
    padding-bottom: 0px;
  }
  .careers-text1 {
    text-align: center;
  }
  .careers-blog1 {
    padding-top: 0px;
    padding-left: var(--dl-space-space-unit);
    padding-right: var(--dl-space-space-unit);
    padding-bottom: var(--dl-space-space-twounits);
  }
  .careers-container22 {
    margin-bottom: var(--dl-space-space-twounits);
  }
  .careers-container24 {
    padding-top: 0px;
  }
  .careers-button {
    font-size: 16px;
  }
}
