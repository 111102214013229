.contact-us-container {
    width: 100%;
    display: flex;
    overflow: auto;
    min-height: 100vh;
    align-items: center;
    flex-direction: column;
  }
  .contact-us-container01 {
    flex: 0 0 auto;
    width: 100%;
    height: 50vh;
    display: flex;
    align-items: center;
    background-size: cover;
    background-image: url("../../../public/playground_assets/contact-us-1500h.jpg");
  }
  .contact-us-container02 {
    flex: 0 0 auto;
    width: 100%;
    display: flex;
    margin-top: 0px;
    align-items: center;
    padding-top: 0px;
    padding-bottom: 0px;
    justify-content: center;
  }
  .contact-us-text {
    color: var(--dl-color-gray-white);
    font-size: 50px;
    font-style: normal;
    text-align: center;
    font-family: Poppins;
    font-weight: 700;
  }
  .contact-us-container03 {
    flex: 0 0 auto;
    width: 100%;
    height: auto;
    display: flex;
    max-width: 1600px;
    align-items: center;
    margin-left: 0px;
    margin-right: 0px;
    padding-left: 0px;
    margin-bottom: var(--dl-space-space-threeunits);
    padding-right: 0px;
  }
  .contact-us-container04 {
    flex: 0 0 auto;
    width: 30%;
    display: flex;
    align-items: center;
    padding-left: 0px;
    flex-direction: row;
  }
  .contact-us-container05 {
    flex: 0 0 auto;
    width: 100%;
    display: flex;
    align-items: flex-start;
    margin-right: 00px;
    flex-direction: column;
    justify-content: center;
  }
  .contact-us-container06 {
    flex: 0 0 auto;
    width: 100%;
    display: flex;
    margin-top: var(--dl-space-space-threeunits);
    align-items: flex-start;
    margin-bottom: var(--dl-space-space-threeunits);
    padding-right: 0px;
    flex-direction: column;
  }
  .contact-us-container07 {
    flex: 0 0 auto;
    width: 100%;
    display: flex;
    align-items: flex-start;
    flex-direction: row;
  }
  .contact-us-icon {
    fill: var(--dl-color-gray-black);
    width: 40px;
    height: 40px;
    align-self: flex-start;
  }
  .contact-us-text1 {
    color: var(--dl-color-gray-black);
    width: 100%;
    height: auto;
    font-size: 20px;
    font-family: Poppins;
    line-height: 2;
    margin-left: 0px;
    margin-bottom: var(--dl-space-space-unit);
  }
  .contact-us-text4 {
    font-weight: 300;
  }
  .contact-us-text5 {
    font-weight: 300;
  }
  .contact-us-container08 {
    flex: 0 0 auto;
    width: 100%;
    height: auto;
    display: flex;
    align-items: center;
    justify-content: flex-start;
  }
  .contact-us-icon2 {
    fill: var(--dl-color-gray-black);
    width: 35px;
    height: 35px;
    align-self: flex-start;
    margin-left: 0px;
  }
  .contact-us-link {
    color: var(--dl-color-gray-black);
    font-size: 20px;
    font-family: Poppins;
    margin-left: 0px;
    margin-bottom: 0px;
    text-decoration: none;
  }
  .contact-us-container09 {
    flex: 0 0 auto;
    width: 100%;
    height: auto;
    display: flex;
    margin-top: var(--dl-space-space-unit);
    align-items: center;
  }
  .contact-us-icon4 {
    fill: var(--dl-color-gray-black);
    width: 32px;
    height: 32px;
    align-self: flex-start;
  }
  .contact-us-link1 {
    color: var(--dl-color-gray-black);
    font-size: 20px;
    font-family: Poppins;
    margin-left: 2px;
    text-decoration: none;
  }
  .contact-us-container10 {
    flex: 0 0 auto;
    width: 70%;
    height: auto;
    display: flex;
    align-items: center;
    flex-direction: column;
  }
  .contact-us-container11 {
    flex: 0 0 auto;
    width: 100%;
    height: auto;
    display: flex;
    margin-top: var(--dl-space-space-twounits);
    align-items: flex-start;
    margin-bottom: var(--dl-space-space-twounits);
    justify-content: center;
  }
  .contact-us-text6 {
    color: var(--dl-color-gray-black);
    font-size: 30px;
    font-family: Poppins;
  }
  .contact-us-container12 {
    flex: 0 0 auto;
    width: 100%;
    height: auto;
    display: flex;
    align-items: center;
    flex-direction: row;
  }
  .contact-us-form {
    width: 100%;
    height: auto;
    display: flex;
    padding: 10px;
    max-width: 100%;
    align-self: center;
    margin-top: 0px;
    border-color: var(--dl-color-gray-black);
    border-width: 0px;
    border-radius: 0px;
    margin-bottom: 0px;
    flex-direction: row;
    background-color: rgba(255, 255, 255, 0.41);
    border-left-width: 1px;
  }
  .contact-us-container13 {
    flex: 0 0 auto;
    width: 100%;
    height: auto;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
  }
  .contact-us-container14 {
    flex: 0 0 auto;
    width: 100%;
    height: auto;
    display: flex;
    align-items: flex-start;
    justify-content: center;
  }
  .contact-us-container15 {
    flex: 0 0 auto;
    width: 50%;
    height: auto;
    display: flex;
    align-items: center;
    padding-top: var(--dl-space-space-unit);
    padding-left: var(--dl-space-space-unit);
    margin-bottom: var(--dl-space-space-twounits);
    padding-right: var(--dl-space-space-unit);
    flex-direction: column;
    padding-bottom: 0px;
  }
  .contact-us-textinput {
    width: 100%;
    height: 50px;
    font-family: Poppins;
    border-color: var(--dl-color-primary-100);
    border-width: 0px;
    padding-left: 0px;
    border-radius: 0px;
    margin-bottom: var(--dl-space-space-twounits);
    border-bottom-width: 1px;
    outline: none;
  }
  .contact-us-textinput1 {
    width: 100%;
    height: 50px;
    font-family: Poppins;
    border-color: var(--dl-color-primary-100);
    border-width: 0px;
    padding-left: 0px;
    border-radius: 0px;
    margin-bottom: 0px;
    border-bottom-width: 1px;
    outline: none;
  }
  .contact-us-container16 {
    flex: 0 0 auto;
    width: 50%;
    display: flex;
    align-items: flex-start;
    flex-direction: column;
  }
  .contact-us-container17 {
    flex: 0 0 auto;
    width: 100%;
    height: auto;
    display: flex;
    padding: var(--dl-space-space-unit);
    align-items: center;
    flex-direction: column;
  }
  .contact-us-textinput2 {
    width: 100%;
    height: 50px;
    font-family: Poppins;
    border-color: var(--dl-color-primary-100);
    border-width: 0px;
    padding-left: 0px;
    border-radius: 0px;
    margin-bottom: var(--dl-space-space-twounits);
    border-bottom-width: 1px;
    outline: none;
  }
  .contact-us-textinput3 {
    width: 100%;
    height: 50px;
    font-family: Poppins;
    border-color: var(--dl-color-primary-100);
    border-width: 0px;
    padding-left: 0px;
    border-radius: 0px;
    margin-bottom: 0px;
    border-bottom-width: 1px;
    outline: none;
  }
  .contact-us-container18 {
    flex: 0 0 auto;
    width: 100%;
    height: auto;
    display: flex;
    margin-top: 0px;
    align-items: center;
    padding-top: 0px;
    padding-left: var(--dl-space-space-unit);
    padding-right: var(--dl-space-space-unit);
    flex-direction: column;
    padding-bottom: var(--dl-space-space-unit);
  }
  .contact-us-textarea {
    width: 100%;
    height: 136px;
    resize: none;
    border-color: var(--dl-color-primary-100);
    border-width: 0px;
    padding-left: 0px;
    border-radius: 0px;
    margin-bottom: var(--dl-space-space-threeunits);
    border-bottom-width: 1px;
    outline: none;
  }
  .contact-us-button {
    color: var(--dl-color-gray-white);
    cursor: pointer;
    font-size: 20px;
    font-style: normal;
    transition: 0.3s;
    font-family: Montserrat;
    font-weight: 700;
    padding-top: var(--dl-space-space-unit);
    border-width: 0px;
    padding-left: var(--dl-space-space-threeunits);
    border-radius: 10px;
    padding-right: var(--dl-space-space-threeunits);
    padding-bottom: var(--dl-space-space-unit);
    background-image: linear-gradient(90deg, rgb(88, 181, 107) 0%, rgb(31, 149, 141) 100%);
  }
  .contact-us-button:hover {
    background-image: linear-gradient(90deg, rgb(0, 0, 0) 0.00%,rgb(0, 0, 0) 100.00%),linear-gradient(90deg, rgb(88, 181, 107) 0.00%,rgb(31, 149, 141) 100.00%);
  }
  .contact-us-container19 {
    flex: 0 0 auto;
    width: 100%;
    height: auto;
    display: flex;
    max-width: 1600PX;
    margin-top: 0px;
    justify-content: center;
  }
  .contact-us-div {
    width: 100%;
  }
  @media(max-width: 1600px) {
    .contact-us-container02 {
      margin-top: 0px;
    }
    .contact-us-text {
      font-size: 50px;
    }
    .contact-us-container03 {
      max-width: 1400px;
      padding-left: 0px;
    }
    .contact-us-icon {
      fill: var(--dl-color-gray-black);
      width: 35px;
      height: 35px;
    }
    .contact-us-text1 {
      color: var(--dl-color-gray-black);
    }
    .contact-us-icon2 {
      fill: var(--dl-color-gray-black);
      width: 35px;
      height: 35px;
    }
    .contact-us-link {
      color: var(--dl-color-gray-black);
    }
    .contact-us-container09 {
      align-items: center;
    }
    .contact-us-icon4 {
      fill: var(--dl-color-gray-black);
      width: 35px;
      height: 35px;
      align-self: center;
    }
    .contact-us-link1 {
      color: var(--dl-color-gray-black);
    }
  }
  @media(max-width: 1200px) {
    .contact-us-text {
      font-size: 50px;
    }
    .contact-us-container03 {
      max-width: 1000px;
    }
    .contact-us-text1 {
      color: var(--dl-color-gray-black);
    }
    .contact-us-link {
      color: var(--dl-color-gray-black);
    }
    .contact-us-container14 {
      height: auto;
    }
    .contact-us-container15 {
      margin-bottom: 0px;
    }
    .contact-us-textinput1 {
      margin-bottom: var(--dl-space-space-unit);
    }
    .contact-us-container16 {
      width: 50%;
      height: auto;
    }
    .contact-us-container17 {
      width: 100%;
    }
    .contact-us-textinput3 {
      margin-bottom: var(--dl-space-space-unit);
    }
    .contact-us-button {
      font-size: 16px;
    }
  }
  @media(max-width: 991px) {
    .contact-us-text {
      font-size: 50px;
    }
    .contact-us-container03 {
      max-width: 800px;
    }
    .contact-us-icon {
      width: 20px;
      height: 20px;
    }
    .contact-us-container08 {
      flex-wrap: wrap;
    }
    .contact-us-icon2 {
      width: 20px;
      height: 20px;
    }
    .contact-us-container09 {
      flex-wrap: wrap;
    }
    .contact-us-icon4 {
      width: 20px;
      height: 20px;
    }
    .contact-us-link1 {
      font-size: 18px;
    }
  }
  @media(max-width: 767px) {
    .contact-us-text {
      font-size: 40px;
    }
    .contact-us-container03 {
      padding: var(--dl-space-space-twounits);
      flex-direction: column;
    }
    .contact-us-container04 {
      width: 100%;
      margin-bottom: var(--dl-space-space-threeunits);
    }
    .contact-us-container05 {
      align-items: center;
    }
    .contact-us-container06 {
      margin-top: 0px;
      align-items: center;
      padding-left: 0px;
      margin-bottom: 0px;
      padding-right: 0px;
    }
    .contact-us-container07 {
      justify-content: center;
    }
    .contact-us-text1 {
      text-align: left;
    }
    .contact-us-container10 {
      width: 90%;
      padding: 0px0;
      padding-top: 0px;
      border-color: var(--dl-color-gray-black);
      border-width: 0px;
      padding-left: 0px;
      padding-right: 0px;
      padding-bottom: 0px;
      border-top-width: 1px;
    }
    .contact-us-container11 {
      align-self: center;
    }
    .contact-us-text6 {
      font-size: 16px;
      text-align: center;
    }
    .contact-us-form {
      padding: 0px;
      border-left-width: 0px;
    }
    .contact-us-container14 {
      flex-direction: column;
    }
    .contact-us-container15 {
      width: 100%;
      align-items: center;
      padding-top: 0px;
      padding-left: 0px;
      padding-right: 0px;
    }
    .contact-us-textinput {
      font-size: 14px;
    }
    .contact-us-textinput1 {
      font-size: 14px;
    }
    .contact-us-container16 {
      width: 100%;
    }
    .contact-us-container17 {
      width: 100%;
      display: block;
      padding-top: var(--dl-space-space-halfunit);
      padding-left: 0px;
      margin-bottom: var(--dl-space-space-unit);
      padding-right: 0px;
      padding-bottom: 0px;
    }
    .contact-us-textinput2 {
      font-size: 14px;
    }
    .contact-us-textinput3 {
      font-size: 14px;
      margin-bottom: 0px;
    }
    .contact-us-container18 {
      padding-left: 0px;
      padding-right: 0px;
      padding-bottom: 0px;
    }
    .contact-us-textarea {
      font-size: 14px;
    }
    .contact-us-button {
      font-size: 14px;
    }
  }
  @media(max-width: 479px) {
    .contact-us-container01 {
      height: 30vh;
      background-position: center;
    }
    .contact-us-text {
      font-size: 20px;
    }
    .contact-us-icon {
      width: 20px;
      height: 20px;
    }
    .contact-us-text1 {
      color: var(--dl-color-gray-black);
      font-size: 12px;
      align-self: flex-start;
      text-align: left;
    }
    .contact-us-icon2 {
      width: 20px;
      height: 20px;
    }
    .contact-us-link {
      font-size: 12px;
      margin-left: var(--dl-space-space-halfunit);
    }
    .contact-us-icon4 {
      width: 20px;
      height: 20px;
    }
    .contact-us-link1 {
      font-size: 12px;
      margin-left: 5px;
    }
    .contact-us-container10 {
      width: 100%;
      padding-top: 0px;
      padding-left: 0p0x;
      padding-right: 0p0x;
      padding-bottom: 0px;
    }
    .contact-us-text6 {
      font-size: 14px;
    }
    .contact-us-container15 {
      padding: 0px;
      margin-bottom: 0px;
    }
    .contact-us-textinput {
      font-size: 12px;
      margin-bottom: var(--dl-space-space-halfunit);
    }
    .contact-us-textinput1 {
      font-size: 12px;
    }
    .contact-us-container17 {
      padding-top: 0px;
      padding-left: 0px;
      padding-right: 0px;
    }
    .contact-us-textinput2 {
      font-size: 12px;
      margin-bottom: var(--dl-space-space-halfunit);
    }
    .contact-us-textinput3 {
      font-size: 12px;
    }
    .contact-us-container18 {
      padding: 0px;
    }
    .contact-us-textarea {
      height: 100%;
      font-size: 12px;
      margin-bottom: var(--dl-space-space-halfunit);
    }
    .contact-us-button {
      font-size: 12px;
    }
  }
 
  [data-thq="thq-dropdown"]:hover > [data-thq="thq-dropdown-list"] {
    display: flex;
  }
  
  [data-thq="thq-dropdown"]:hover > div [data-thq="thq-dropdown-arrow"] {
    transform: rotate(90deg);
  }
  