.footer-container {
  width: 100%;
  display: flex;
  overflow: auto;
  align-items: center;
  flex-direction: column;
}
.footer-footer {
  width: 100%;
  display: flex;
  max-width: 100%;
  align-items: center;
  padding-top: var(--dl-space-space-twounits);
  padding-left: var(--dl-space-space-threeunits);
  padding-right: var(--dl-space-space-threeunits);
  flex-direction: column;
  padding-bottom: var(--dl-space-space-twounits);
  justify-content: space-between;
  background-image: linear-gradient(90deg, rgb(88, 181, 107) 0.00%,rgb(31, 149, 141) 100.00%);
}
.footer-container01 {
  flex: 0 0 auto;
  width: 100%;
  height: 100px;
  display: flex;
  max-width: 1600px;
  align-items: flex-start;
}
.footer-container02 {
  width: 100%;
  display: flex;
  align-items: flex-start;
  margin-bottom: var(--dl-space-space-twounits);
  flex-direction: row;
  justify-content: center;
}
.footer-navlink {
  display: contents;
}
.footer-image {
  width: var(--dl-size-size-xlarge);
  height: auto;
  text-decoration: none;
}
.footer-links-container {
  display: flex;
  max-width: 1600px;
  flex-direction: row;
  justify-content: center;
}
.footer-container03 {
  display: flex;
  align-items: flex-start;
  flex-direction: row;
  justify-content: space-between;
}
.footer-container04 {
  flex: 0 0 auto;
  display: flex;
  align-items: flex-start;
  margin-right: 192px;
  flex-direction: column;
  justify-content: flex-start;
}
.footer-text {
  color: var(--dl-color-gray-white);
  font-family: Poppins;
  font-weight: 700;
  margin-bottom: var(--dl-space-space-oneandhalfunits);
}
.footer-navlink1 {
  color: var(--dl-color-gray-white);
  text-align: center;
  transition: 0.3s;
  font-family: Poppins;
  margin-bottom: var(--dl-space-space-unit);
  text-decoration: none;
}
.footer-navlink1:hover {
  color: var(--dl-color-primary-700);
}
.footer-navlink2 {
  color: var(--dl-color-gray-white);
  text-align: center;
  transition: 0.3s;
  font-family: Poppins;
  margin-bottom: var(--dl-space-space-unit);
  text-decoration: none;
}
.footer-navlink2:hover {
  color: var(--dl-color-primary-700);
}
.footer-navlink3 {
  color: var(--dl-color-gray-white);
  text-align: center;
  transition: 0.3s;
  font-family: Poppins;
  margin-bottom: var(--dl-space-space-unit);
  text-decoration: none;
}
.footer-navlink3:hover {
  color: var(--dl-color-primary-700);
}
.footer-container05 {
  flex: 0 0 auto;
  display: flex;
  align-items: flex-start;
  margin-right: 192px;
  flex-direction: column;
  justify-content: center;
}
.footer-text01 {
  color: var(--dl-color-gray-white);
  font-weight: 700;
  margin-bottom: var(--dl-space-space-oneandhalfunits);
}
.footer-navlink4 {
  color: var(--dl-color-gray-white);
  text-align: center;
  transition: 0.3s;
  font-family: Poppins;
  margin-bottom: var(--dl-space-space-unit);
  text-decoration: none;
}
.footer-navlink4:hover {
  color: var(--dl-color-primary-700);
}
.footer-navlink5 {
  color: var(--dl-color-gray-white);
  text-align: center;
  transition: 0.3s;
  font-family: Poppins;
  margin-bottom: var(--dl-space-space-unit);
  text-decoration: none;
}
.footer-navlink5:hover {
  color: var(--dl-color-primary-700);
}
.footer-navlink6 {
  color: var(--dl-color-gray-white);
  text-align: center;
  transition: 0.3s;
  font-family: Poppins;
  margin-bottom: var(--dl-space-space-unit);
  text-decoration: none;
}
.footer-navlink6:hover {
  color: var(--dl-color-primary-700);
}
.footer-container06 {
  display: flex;
  align-items: flex-start;
  flex-direction: row;
  justify-content: space-between;
}
.footer-container07 {
  flex: 0 0 auto;
  display: flex;
  align-items: flex-start;
  margin-right: 192px;
  flex-direction: column;
  justify-content: flex-start;
}
.footer-text02 {
  color: var(--dl-color-gray-white);
  font-weight: 700;
  margin-bottom: var(--dl-space-space-oneandhalfunits);
}
.footer-text03 {
  color: var(--dl-color-gray-white);
  text-align: center;
  transition: 0.3s;
  font-family: Poppins;
  margin-bottom: var(--dl-space-space-unit);
  text-decoration: none;
}
html {
  font-family: Inter;
  font-size: 16px;
}
html {  line-height: 1.15;}body {  margin: 0;}* {  box-sizing: border-box;  border-width: 0;  border-style: solid;}p,li,ul,pre,div,h1,h2,h3,h4,h5,h6,figure,blockquote,figcaption {  margin: 0;  padding: 0;}button {  background-color: transparent;}button,input,optgroup,select,textarea {  font-family: inherit;  font-size: 100%;  line-height: 1.15;  margin: 0;}button,select {  text-transform: none;}button,[type="button"],[type="reset"],[type="submit"]button::-moz-focus-inner,[type="button"]::-moz-focus-inner,[type="reset"]::-moz-focus-inner,[type="submit"]::-moz-focus-inner {  border-style: none;  padding: 0;}button:-moz-focus,[type="button"]:-moz-focus,[type="reset"]:-moz-focus,[type="submit"]:-moz-focus {  outline: 1px dotted ButtonText;}a {  color: inherit;  text-decoration: inherit;}input {  padding: 2px 4px;}img {  display: block;}html { scroll-behavior: smooth  }

body {
  font-weight: 400;
  font-style:normal;
  text-decoration: none;
  text-transform: none;
  letter-spacing: normal;
  line-height: 1.15;
  color: var(--dl-color-gray-black);
  background-color: var(--dl-color-gray-white);

}
[data-thq="thq-dropdown"]:hover > [data-thq="thq-dropdown-list"] {
  display: flex;
}

[data-thq="thq-dropdown"]:hover > div [data-thq="thq-dropdown-arrow"] {
  transform: rotate(90deg);
}
.footer-text03:hover {
  color: var(--dl-color-primary-700);
}
.footer-text04 {
  color: var(--dl-color-gray-white);
  text-align: center;
  transition: 0.3s;
  font-family: Poppins;
  margin-bottom: var(--dl-space-space-unit);
  text-decoration: none;
}
.footer-text04:hover {
  color: var(--dl-color-primary-700);
}
.footer-text05 {
  color: var(--dl-color-gray-white);
  text-align: center;
  transition: 0.3s;
  font-family: Poppins;
  margin-bottom: var(--dl-space-space-unit);
  text-decoration: none;
}
.footer-text05:hover {
  color: var(--dl-color-primary-700);
}
.footer-container08 {
  flex: 0 0 auto;
  display: flex;
  align-items: flex-start;
  margin-right: 192px;
  flex-direction: column;
  justify-content: flex-start;
}
.footer-text06 {
  color: var(--dl-color-gray-white);
  font-weight: 700;
  margin-bottom: var(--dl-space-space-oneandhalfunits);
}
.footer-navlink7 {
  color: var(--dl-color-gray-white);
  text-align: center;
  transition: 0.3s;
  font-family: Poppins;
  margin-bottom: var(--dl-space-space-unit);
  text-decoration: none;
}
.footer-navlink7:hover {
  color: var(--dl-color-primary-700);
}
.footer-navlink8 {
  color: var(--dl-color-gray-white);
  text-align: center;
  transition: 0.3s;
  font-family: Poppins;
  margin-bottom: var(--dl-space-space-unit);
  text-decoration: none;
}
.footer-navlink8:hover {
  color: var(--dl-color-primary-700);
}
.footer-text07 {
  color: var(--dl-color-gray-white);
  text-align: center;
  transition: 0.3s;
  font-family: Poppins;
  margin-bottom: var(--dl-space-space-unit);
  text-decoration: none;
}
.footer-text07:hover {
  color: var(--dl-color-primary-700);
}
.footer-container09 {
  flex: 0 0 auto;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: flex-start;
}
.footer-container10 {
  flex: 0 0 auto;
  width: 200px;
  display: flex;
  align-items: flex-start;
  flex-direction: row;
}
.footer-container11 {
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.footer-container12 {
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.footer-text08 {
  color: var(--dl-color-gray-white);
  font-weight: 700;
  margin-bottom: var(--dl-space-space-oneandhalfunits);
}
.footer-container13 {
  flex: 0 0 auto;
  width: 200px;
  display: flex;
  align-items: flex-start;
  flex-direction: row;
}
.footer-icon {
  fill: var(--dl-color-gray-white);
  width: 40px;
  height: 40px;
  align-self: flex-start;
}
.footer-text09 {
  color: var(--dl-color-gray-white);
  height: auto;
  font-family: Poppins;
  margin-left: 0px;
  margin-bottom: var(--dl-space-space-unit);
}
.footer-container14 {
  flex: 0 0 auto;
  width: 100%;
  height: auto;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
.footer-icon2 {
  fill: var(--dl-color-gray-white);
  width: 30px;
  height: 30px;
  align-self: flex-start;
  margin-left: 0px;
}
.footer-link {
  color: var(--dl-color-gray-white);
  font-family: Poppins;
  margin-left: 0px;
  margin-bottom: 0px;
  text-decoration: none;
}
.footer-container15 {
  flex: 0 0 auto;
  width: 100%;
  height: auto;
  display: flex;
  align-items: center;
}
.footer-icon4 {
  fill: var(--dl-color-gray-white);
  width: 40px;
  height: 40px;
  align-self: flex-start;
}
.footer-link1 {
  color: var(--dl-color-gray-white);
  font-family: Poppins;
  margin-left: 2px;
  text-decoration: none;
}
.footer-container16 {
  display: flex;
  position: relative;
  margin-top: 0px;
  align-items: center;
  justify-content: flex-end;
}
.footer-container17 {
  display: flex;
  position: relative;
  margin-top: 0px;
  align-items: center;
  justify-content: center;
}
.footer-image1 {
  width: 30px;
  cursor: pointer;
  object-fit: cover;
  transition: 0.3s;
  margin-right: var(--dl-space-space-halfunit);
}
.footer-image1:hover {
  transform: scale(1.2);
}
.footer-image2 {
  width: 30px;
  cursor: pointer;
  object-fit: cover;
  transition: 0.3s;
  margin-right: var(--dl-space-space-halfunit);
}
.footer-image2:hover {
  transform: scale(1.2);
}
.footer-image3 {
  width: 30px;
  cursor: pointer;
  object-fit: cover;
  transition: 0.3s;
  margin-right: var(--dl-space-space-halfunit);
}
.footer-image3:hover {
  transform: scale(1.2);
}
.footer-link2 {
  display: contents;
}
.footer-image4 {
  width: 30px;
  cursor: pointer;
  object-fit: cover;
  transition: 0.3s;
  text-decoration: none;
}
.footer-image4:hover {
  transform: scale(1.2);
}
.footer-container18 {
  width: 100%;
  display: flex;
  max-width: 100%;
  align-items: center;
  padding-top: var(--dl-space-space-twounits);
  padding-left: var(--dl-space-space-threeunits);
  padding-right: var(--dl-space-space-threeunits);
  flex-direction: column;
  padding-bottom: var(--dl-space-space-twounits);
  justify-content: space-between;
  background-image: linear-gradient(90deg, rgb(88, 181, 107) 0.00%,rgb(31, 149, 141) 100.00%);
}
.footer-text15 {
  color: var(--dl-color-gray-white);
  font-size: 14px;
  font-style: normal;
  font-family: Poppins;
  font-weight: 300;
}
@media(max-width: 1600px) {
  .footer-footer {
    width: 100%;
    background-color: var(--dl-color-primary-100);
  }
  .footer-links-container {
    flex-direction: row;
  }
  .footer-text {
    color: var(--dl-color-gray-white);
  }
  .footer-navlink1 {
    color: var(--dl-color-gray-white);
  }
  .footer-navlink2 {
    color: var(--dl-color-gray-white);
  }
  .footer-navlink3 {
    color: var(--dl-color-gray-white);
  }
  .footer-container05 {
    margin-right: 180px;
  }
  .footer-text01 {
    color: var(--dl-color-gray-white);
  }
  .footer-navlink4 {
    color: var(--dl-color-gray-white);
  }
  .footer-navlink5 {
    color: var(--dl-color-gray-white);
  }
  .footer-navlink6 {
    color: var(--dl-color-gray-white);
  }
  .footer-container07 {
    margin-right: 180px;
  }
  .footer-text02 {
    color: var(--dl-color-gray-white);
  }
  .footer-text03 {
    color: var(--dl-color-gray-white);
  }
  .footer-text04 {
    color: var(--dl-color-gray-white);
  }
  .footer-text05 {
    color: var(--dl-color-gray-white);
  }
  .footer-container08 {
    margin-right: 180px;
  }
  .footer-text06 {
    color: var(--dl-color-gray-white);
  }
  .footer-navlink7 {
    color: var(--dl-color-gray-white);
  }
  .footer-navlink8 {
    color: var(--dl-color-gray-white);
  }
  .footer-text07 {
    color: var(--dl-color-gray-white);
  }
  .footer-text08 {
    color: var(--dl-color-gray-white);
  }
  .footer-icon {
    fill: var(--dl-color-gray-white);
    width: 35px;
    height: 35px;
  }
  .footer-text09 {
    color: var(--dl-color-gray-white);
  }
  .footer-icon2 {
    fill: var(--dl-color-gray-white);
    width: 20px;
    height: 20px;
  }
  .footer-link {
    color: var(--dl-color-gray-white);
  }
  .footer-container15 {
    align-items: center;
  }
  .footer-icon4 {
    fill: var(--dl-color-gray-white);
    width: 35px;
    height: 35px;
    align-self: center;
  }
  .footer-link1 {
    color: var(--dl-color-gray-white);
  }
  .footer-container16 {
    margin-top: 0px;
    flex-direction: row;
  }
  .footer-container17 {
    margin-top: 0px;
    flex-direction: row;
  }
  .footer-container18 {
    width: 100%;
    background-color: var(--dl-color-primary-100);
  }
  .footer-text15 {
    font-family: Inter;
  }
}
@media(max-width: 1200px) {
  .footer-footer {
    width: 100%;
  }
  .footer-container01 {
    justify-content: center;
  }
  .footer-container02 {
    align-self: stretch;
    justify-content: center;
  }
  .footer-image {
    margin-right: 0px;
  }
  .footer-container04 {
    margin-right: 120px;
  }
  .footer-navlink1 {
    font-size: 14px;
  }
  .footer-navlink2 {
    font-size: 14px;
  }
  .footer-navlink3 {
    font-size: 14px;
  }
  .footer-container05 {
    margin-right: 120px;
  }
  .footer-navlink4 {
    font-size: 14px;
  }
  .footer-navlink5 {
    font-size: 14px;
  }
  .footer-navlink6 {
    font-size: 14px;
  }
  .footer-container07 {
    margin-right: 120px;
  }
  .footer-text03 {
    font-size: 14px;
  }
  .footer-text04 {
    font-size: 14px;
  }
  .footer-text05 {
    font-size: 14px;
  }
  .footer-container08 {
    margin-right: 120px;
  }
  .footer-navlink7 {
    font-size: 14px;
  }
  .footer-navlink8 {
    font-size: 14px;
  }
  .footer-text07 {
    font-size: 14px;
  }
  .footer-container09 {
    margin-right: 0px;
  }
  .footer-text08 {
    color: var(--dl-color-gray-white);
    font-weight: 700;
  }
  .footer-icon {
    width: 20px;
    height: 20px;
  }
  .footer-text09 {
    color: var(--dl-color-gray-white);
    font-size: 14px;
  }
  .footer-link {
    color: var(--dl-color-gray-white);
    font-size: 14px;
    margin-bottom: var(--dl-space-space-unit);
  }
  .footer-icon4 {
    width: 20px;
    height: 20px;
  }
  .footer-link1 {
    font-size: 14px;
  }
  .footer-container18 {
    width: 100%;
  }
}
@media(max-width: 991px) {
  .footer-footer {
    flex-direction: column;
  }
  .footer-container02 {
    align-items: center;
  }
  .footer-links-container {
    width: auto;
    margin-top: var(--dl-space-space-twounits);
    align-items: flex-start;
    flex-direction: row;
  }
  .footer-container03 {
    margin-right: 0px;
  }
  .footer-container04 {
    margin-right: 50px;
  }
  .footer-container05 {
    margin-right: 50px;
  }
  .footer-container06 {
    width: auto;
    align-items: flex-start;
  }
  .footer-container07 {
    margin-right: 50px;
  }
  .footer-container08 {
    margin-right: 50px;
  }
  .footer-container10 {
    width: 100%;
  }
  .footer-icon2 {
    width: 35px;
  }
  .footer-icon4 {
    width: 25px;
    height: 25px;
  }
  .footer-container18 {
    flex-direction: column;
  }
}
@media(max-width: 767px) {
  .footer-footer {
    padding-left: var(--dl-space-space-twounits);
    padding-right: var(--dl-space-space-twounits);
  }
  .footer-container01 {
    justify-content: center;
  }
  .footer-container02 {
    margin-bottom: 0px;
  }
  .footer-image {
    margin-bottom: 0px;
  }
  .footer-links-container {
    width: 100%;
    margin-top: var(--dl-space-space-twounits);
    align-items: center;
    margin-bottom: var(--dl-space-space-twounits);
    flex-direction: column;
    justify-content: space-between;
  }
  .footer-container03 {
    width: 100%;
    align-items: flex-start;
    margin-bottom: var(--dl-space-space-twounits);
    flex-direction: row;
    justify-content: center;
  }
  .footer-container04 {
    align-items: center;
    margin-right: var(--dl-space-space-threeunits);
    margin-bottom: 0px;
  }
  .footer-container05 {
    align-items: center;
    margin-left: var(--dl-space-space-threeunits);
    margin-right: 0px;
  }
  .footer-container06 {
    margin-top: var(--dl-space-space-twounits);
    align-items: flex-start;
    margin-bottom: var(--dl-space-space-twounits);
    flex-direction: row;
  }
  .footer-container07 {
    align-items: center;
    margin-right: var(--dl-space-space-threeunits);
    margin-bottom: 0px;
  }
  .footer-container08 {
    align-items: center;
    margin-left: var(--dl-space-space-threeunits);
    margin-right: 0px;
  }
  .footer-container09 {
    width: 100%;
    align-items: center;
    margin-left: 0px;
    flex-direction: column;
  }
  .footer-container10 {
    width: 100%;
  }
  .footer-container11 {
    align-items: center;
  }
  .footer-container12 {
    align-items: center;
  }
  .footer-container13 {
    width: 100%;
    justify-content: center;
  }
  .footer-text09 {
    text-align: left;
  }
  .footer-container14 {
    justify-content: center;
  }
  .footer-container15 {
    margin-top: var(--dl-space-space-unit);
    justify-content: center;
  }
  .footer-container16 {
    width: 100%;
    margin-top: 0px;
    justify-content: center;
  }
  .footer-container17 {
    width: 100%;
    margin-top: 0px;
    justify-content: center;
  }
  .footer-container18 {
    padding-left: var(--dl-space-space-twounits);
    padding-right: var(--dl-space-space-twounits);
  }
}
@media(max-width: 479px) {
  .footer-footer {
    padding: var(--dl-space-space-unit);
  }
  .footer-container02 {
    height: auto;
  }
  .footer-image {
    width: var(--dl-size-size-medium);
    margin-bottom: 0px;
  }
  .footer-links-container {
    margin-top: var(--dl-space-space-unit);
    align-items: flex-start;
    margin-bottom: var(--dl-space-space-unit);
    flex-direction: column;
    justify-content: space-between;
  }
  .footer-container03 {
    align-items: flex-start;
    margin-right: 0px;
    flex-direction: row;
    justify-content: center;
  }
  .footer-container04 {
    margin-left: 0px;
    margin-right: var(--dl-space-space-twounits);
  }
  .footer-text {
    font-size: 14px;
  }
  .footer-navlink1 {
    font-size: 12px;
  }
  .footer-navlink2 {
    font-size: 12px;
  }
  .footer-navlink3 {
    font-size: 12px;
  }
  .footer-container05 {
    align-self: stretch;
    align-items: center;
    margin-left: var(--dl-space-space-twounits);
    justify-content: flex-start;
  }
  .footer-text01 {
    font-size: 14px;
  }
  .footer-navlink4 {
    font-size: 12px;
  }
  .footer-navlink5 {
    font-size: 12px;
  }
  .footer-navlink6 {
    font-size: 12px;
  }
  .footer-container06 {
    width: 100%;
    margin-top: 00px;
    align-items: flex-start;
    margin-bottom: 00px;
    flex-direction: row;
    justify-content: center;
  }
  .footer-container07 {
    margin-right: var(--dl-space-space-twounits);
  }
  .footer-text02 {
    font-size: 14px;
  }
  .footer-text03 {
    font-size: 12px;
  }
  .footer-text04 {
    font-size: 12px;
  }
  .footer-text05 {
    font-size: 12px;
  }
  .footer-container08 {
    margin-left: var(--dl-space-space-twounits);
    justify-content: flex-start;
  }
  .footer-text06 {
    font-size: 14px;
  }
  .footer-navlink7 {
    font-size: 12px;
  }
  .footer-navlink8 {
    font-size: 12px;
  }
  .footer-text07 {
    font-size: 12px;
  }
  .footer-container09 {
    width: 100%;
    margin-top: var(--dl-space-space-twounits);
    align-items: center;
    margin-left: 0px;
  }
  .footer-container10 {
    width: 100%;
  }
  .footer-text08 {
    font-size: 14px;
  }
  .footer-container13 {
    width: 100%;
    justify-content: center;
  }
  .footer-text09 {
    color: var(--dl-color-gray-white);
    font-size: 12px;
    align-self: flex-start;
    text-align: left;
    margin-bottom: var(--dl-space-space-halfunit);
  }
  .footer-container14 {
    margin-bottom: var(--dl-space-space-halfunit);
    justify-content: center;
  }
  .footer-icon2 {
    width: 20px;
    height: auto;
  }
  .footer-link {
    font-size: 12px;
    margin-left: var(--dl-space-space-halfunit);
    margin-bottom: 0px;
  }
  .footer-container15 {
    margin-top: 0px;
    justify-content: center;
  }
  .footer-icon4 {
    width: 20px;
    height: auto;
  }
  .footer-link1 {
    font-size: 12px;
  }
  .footer-container16 {
    margin-top: 0px;
  }
  .footer-container17 {
    align-self: center;
    margin-top: 0px;
    align-items: center;
  }
  .footer-image1 {
    width: 20px;
  }
  .footer-image2 {
    width: 20px;
  }
  .footer-image3 {
    width: 20px;
  }
  .footer-image4 {
    width: 20px;
  }
  .footer-container18 {
    padding: var(--dl-space-space-unit);
  }
  .footer-text15 {
    color: var(--dl-color-gray-white);
    font-size: 10px;
    font-style: normal;
    margin-top: var(--dl-space-space-unit);
    font-family: Poppins;
    font-weight: 300;
    margin-bottom: var(--dl-space-space-unit);
  }
}
/* html {  line-height: 1.15;}body {  margin: 0;}* {  box-sizing: border-box;  border-width: 0;  border-style: solid;}p,li,ul,pre,div,h1,h2,h3,h4,h5,h6,figure,blockquote,figcaption {  margin: 0;  padding: 0;}button {  background-color: transparent;}button,input,optgroup,select,textarea {  font-family: inherit;  font-size: 100%;  line-height: 1.15;  margin: 0;}button,select {  text-transform: none;}button,[type="button"],[type="reset"],[type="submit"] {  -webkit-appearance: button;}button::-moz-focus-inner,[type="button"]::-moz-focus-inner,[type="reset"]::-moz-focus-inner,[type="submit"]::-moz-focus-inner {  border-style: none;  padding: 0;}button:-moz-focus,[type="button"]:-moz-focus,[type="reset"]:-moz-focus,[type="submit"]:-moz-focus {  outline: 1px dotted ButtonText;}a {  color: inherit;  text-decoration: inherit;}input {  padding: 2px 4px;}img {  display: block;}html { scroll-behavior: smooth  }
html {
  font-family: Inter;
  font-size: 16px;
}

body {
  font-weight: 400;
  font-style:normal;
  text-decoration: none;
  text-transform: none;
  letter-spacing: normal;
  line-height: 1.15;
  color: var(--dl-color-gray-black);
  background-color: var(--dl-color-gray-white);

} */
[data-thq="thq-dropdown"]:hover > [data-thq="thq-dropdown-list"] {
  display: flex;
}

[data-thq="thq-dropdown"]:hover > div [data-thq="thq-dropdown-arrow"] {
  transform: rotate(90deg);
}
