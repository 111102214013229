.careerpage-container {
  width: 100%;
  display: flex;
  overflow: auto;
  align-items: center;
  flex-direction: column;
}
.careerpage-container01 {
  flex: 0 0 auto;
  width: 100%;
  height: auto;
  display: flex;
  padding: var(--dl-space-space-oneunits);
  max-width: 1600px;
  align-items: flex-start;
  justify-content: center;
}
.careerpage-container02 {
  flex: 0 0 auto;
  width: 60%;
  height: auto;
  display: flex;
  padding: var(--dl-space-space-unit);
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
.careerpage-container03 {
  flex: 0 0 auto;
  width: 100%;
  height: auto;
  display: flex;
  max-width: 1600px;
  align-items: center;
  padding-top: 0px;
  padding-bottom: var(--dl-space-space-halfunit);
  justify-content: center;
}
.careerpage-text {
  font-size: 25px;
  font-style: normal;
  font-family: Poppins;
  font-weight: 600;
}
.careerpage-container04 {
  flex: 0 0 auto;
  width: 100%;
  height: auto;
  display: flex;
  max-width: 1600px;
  align-items: center;
 
  padding-bottom: var(--dl-space-space-halfunit);
  justify-content: center;
}
.careerpage-text01 {
  font-size: 23px;
  font-style: normal;
  font-family: Poppins;
  font-weight: 600;
}
.careerpage-container05 {
  flex: 0 0 auto;
  width: 100%;
  height: auto;
  display: flex;
  max-width: 1600px;
  align-self: center;
 
  padding-bottom: var(--dl-space-space-unit);
  justify-content: center;
}
.careerpage-ul {
  list-style: circle;
  padding-left: var(--dl-space-space-halfunit);
  list-style-type: inherit;
  list-style-image: none;
  list-style-position: outside;
}
.careerpage-li {
  color: var(--dl-color-gray-black);
  font-family: Poppins;
  margin-bottom: var(--dl-space-space-halfunit);
  list-style: disc;
}
.careerpage-text02 {
  color: var(--dl-color-gray-black);
  font-size: 16px;
  margin-top: 0px;
  font-family: Poppins;
}
.careerpage-li01 {
  color: var(--dl-color-gray-black);
  font-family: Poppins;
  margin-bottom: var(--dl-space-space-halfunit);
}
.careerpage-text03 {
  color: var(--dl-color-gray-black);
  font-size: 16px;
  margin-top: 0px;
  font-family: Poppins;
}
.careerpage-li02 {
  color: var(--dl-color-gray-black);
  font-family: Poppins;
  margin-bottom: var(--dl-space-space-halfunit);
}
.careerpage-text04 {
  color: var(--dl-color-gray-black);
  font-size: 16px;
  margin-top: 0px;
  font-family: Poppins;
}
.careerpage-li03 {
  color: var(--dl-color-gray-black);
  font-family: Poppins;
  margin-bottom: var(--dl-space-space-halfunit);
}
.careerpage-text05 {
  color: var(--dl-color-gray-black);
  font-size: 16px;
  margin-top: 0px;
  font-family: Poppins;
}
.careerpage-li04 {
  color: var(--dl-color-gray-black);
  font-family: Poppins;
  margin-bottom: var(--dl-space-space-halfunit);
}
.careerpage-text06 {
  color: var(--dl-color-gray-black);
  font-size: 16px;
  margin-top: 0px;
  font-family: Poppins;
}
.careerpage-li05 {
  color: var(--dl-color-gray-black);
  font-family: Poppins;
}
.careerpage-text07 {
  color: var(--dl-color-gray-black);
  font-size: 16px;
  margin-top: 0px;
  font-family: Poppins;
}
.careerpage-container06 {
  flex: 0 0 auto;
  width: 100%;
  height: auto;
  display: flex;
  max-width: 1600px;
  align-items: center;
  padding-top: var(--dl-space-space-halfunit);
  margin-bottom: var(--dl-space-space-oneandhalfunits);
  padding-bottom: var(--dl-space-space-halfunit);
  justify-content: center;
}
.careerpage-text08 {
  font-size: 23px;
  font-style: normal;
  font-family: Poppins;
  font-weight: 600;
}
.careerpage-ul1 {
  max-width: 1600px;
  list-style: circle;
  padding-left: var(--dl-space-space-halfunit);
  list-style-type: disc;
  list-style-image: none;
  list-style-position: outside;
}
.careerpage-li06 {
  margin-bottom: var(--dl-space-space-halfunit);
}
.careerpage-text09 {
  color: var(--dl-color-gray-black);
  font-size: 16px;
  font-family: Poppins;
}
.careerpage-li07 {
  margin-bottom: var(--dl-space-space-halfunit);
}
.careerpage-text10 {
  color: var(--dl-color-gray-black);
  font-size: 16px;
  font-family: Poppins;
}
.careerpage-li08 {
  margin-bottom: var(--dl-space-space-halfunit);
}
.careerpage-text11 {
  color: var(--dl-color-gray-black);
  font-size: 16px;
  font-family: Poppins;
}
.careerpage-li09 {
  margin-bottom: var(--dl-space-space-halfunit);
}
.careerpage-text12 {
  color: var(--dl-color-gray-black);
  font-size: 16px;
  font-family: Poppins;
}
.careerpage-container07 {
  flex: 0 0 auto;
  width: 100%;
  height: auto;
  display: flex;
  max-width: 1600px;
  align-items: center;
  padding-top: var(--dl-space-space-unit);
  padding-left: 0px;
  padding-bottom: var(--dl-space-space-unit);
}
.careerpage-button {
  color: var(--dl-color-gray-white);
  cursor: pointer;
  font-size: 20px;
  font-style: normal;
  font-family: Poppins;
  font-weight: 600;
  padding-top: var(--dl-space-space-unit);
  border-width: 0px;
  margin-right: var(--dl-space-space-threeunits);
  padding-bottom: var(--dl-space-space-unit);
  background-color: var(--dl-color-primary-500);
}
.careerpage-container08 {
  flex: 0 0 auto;
  width: auto;
  cursor: pointer;
  height: auto;
  display: flex;
  align-items: center;
  border-radius: var(--dl-radius-radius-radius4);
  padding-right: var(--dl-space-space-twounits);
  justify-content: center;
  background-color: var(--dl-color-primary-300);
}
.careerpage-text13 {
  color: var(--dl-color-gray-white);
  font-size: 20px;
  font-style: normal;
  font-family: Poppins;
  font-weight: 400;
  padding-top: var(--dl-space-space-unit);
  padding-left: var(--dl-space-space-twounits);
  padding-right: var(--dl-space-space-halfunit);
  padding-bottom: var(--dl-space-space-unit);
}
.careerpage-icon {
  fill: var(--dl-color-gray-white);
  width: 24px;
  height: 24px;
}
.careerpage-container09 {
  gap: 50px;
  flex: 0 0 auto;
  width: 40%;
  height: auto;
  align-self: center;
  align-items: center;
  flex-direction: column;
  justify-content: space-between;
}
.careerpage-text14 {
  font-size: 20px;
  text-align: center;
  font-family: Poppins;
  margin-bottom: var(--dl-space-space-unit);
}
.careerpage-container10 {
  flex: 0 0 auto;
  width: 100%;
  cursor: pointer;
  height: auto;
  display: flex;
  position: relative;
  align-items: center;
  flex-direction: column;
}
.careerpage-container11 {
  flex: 0 0 auto;
  width: 100%;
  height: auto;
  display: flex;
  transition: 0.3s;
  align-items: center;
  border-color: var(--dl-color-gray-black);
  border-width: 1px;
  flex-direction: row;
  justify-content: center;
  border-top-width: 0px;
  border-left-width: 0px;
  border-right-width: 0px;
}
.careerpage-container11:hover {
  background-color: var(--dl-color-gray-900);
}
.careerpage-text15 {
  color: var(--dl-color-gray-black);
  font-size: 18px;
  font-style: normal;
  list-style: circle;
  margin-top: var(--dl-space-space-unit);
  text-align: left;
  font-family: Poppins;
  font-weight: 500;
  padding-left: var(--dl-space-space-halfunit);
  margin-bottom: var(--dl-space-space-unit);
  list-style-type: disc;
  list-style-image: none;
  list-style-position: outside;
}
.careerpage-icon02 {
  width: 54px;
  height: auto;
}
.careerpage-container12 {
  flex: 0 0 auto;
  width: 100%;
  height: auto;
  display: flex;
  transition: 0.3s;
  align-items: center;
  border-color: var(--dl-color-gray-black);
  border-width: 1px;
  flex-direction: row;
  justify-content: center;
  border-top-width: 0px;
  border-left-width: 0px;
  border-right-width: 0px;
}
.careerpage-container12:hover {
  background-color: var(--dl-color-gray-900);
}
.careerpage-text16 {
  color: var(--dl-color-gray-black);
  font-size: 18px;
  font-style: normal;
  list-style: circle;
  margin-top: var(--dl-space-space-unit);
  text-align: left;
  font-family: Poppins;
  font-weight: 500;
  padding-left: var(--dl-space-space-halfunit);
  margin-bottom: var(--dl-space-space-unit);
  list-style-type: disc;
  list-style-image: none;
  list-style-position: outside;
}
.careerpage-icon04 {
  width: 54px;
  height: auto;
}
.careerpage-container13 {
  flex: 0 0 auto;
  width: 100%;
  height: auto;
  display: flex;
  transition: 0.3s;
  align-items: center;
  border-color: var(--dl-color-gray-black);
  border-width: 1px;
  flex-direction: row;
  justify-content: center;
  border-top-width: 0px;
  border-left-width: 0px;
  border-right-width: 0px;
}
.careerpage-container13:hover {
  background-color: var(--dl-color-gray-900);
}
.careerpage-text17 {
  color: var(--dl-color-gray-black);
  font-size: 18px;
  font-style: normal;
  list-style: circle;
  margin-top: var(--dl-space-space-unit);
  text-align: left;
  font-family: Poppins;
  font-weight: 500;
  padding-left: var(--dl-space-space-halfunit);
  margin-bottom: var(--dl-space-space-unit);
  list-style-type: disc;
  list-style-image: none;
  list-style-position: outside;
}
.careerpage-icon06 {
  width: 54px;
  height: auto;
}
.careerpage-container14 {
  flex: 0 0 auto;
  width: 100%;
  height: auto;
  display: flex;
  transition: 0.3s;
  align-items: center;
  border-color: var(--dl-color-gray-black);
  border-width: 1px;
  flex-direction: row;
  justify-content: center;
  border-top-width: 0px;
  border-left-width: 0px;
  border-right-width: 0px;
}
.careerpage-container14:hover {
  background-color: var(--dl-color-gray-900);
}
.careerpage-text18 {
  color: var(--dl-color-gray-black);
  font-size: 18px;
  font-style: normal;
  list-style: circle;
  margin-top: var(--dl-space-space-unit);
  text-align: left;
  font-family: Poppins;
  font-weight: 500;
  padding-left: var(--dl-space-space-halfunit);
  margin-bottom: var(--dl-space-space-unit);
  list-style-type: disc;
  list-style-image: none;
  list-style-position: outside;
}
.careerpage-icon08 {
  width: 54px;
  height: auto;
}
@media(max-width: 1600px) {
  .careerpage-container01 {
    max-width: 1200px;
  }
  .careerpage-text09 {
    color: var(--dl-color-gray-black);
    font-size: 16px;
    font-family: Poppins;
  }
  .careerpage-text10 {
    color: var(--dl-color-gray-black);
    font-size: 16px;
    font-family: Poppins;
  }
  .careerpage-text11 {
    color: var(--dl-color-gray-black);
    font-size: 16px;
    font-family: Poppins;
  }
  .careerpage-text12 {
    color: var(--dl-color-gray-black);
    font-size: 16px;
    font-family: Poppins;
  }
  .careerpage-button {
    color: var(--dl-color-gray-white);
    font-size: 20px;
    font-style: normal;
    font-family: Poppins;
    font-weight: 400;
    background-color: var(--dl-color-primary-500);
  }
  .careerpage-text13 {
    color: var(--dl-color-gray-white);
    font-size: 20px;
    font-style: normal;
    font-family: Poppins;
    font-weight: 400;
  }
}
@media(max-width: 1200px) {
  .careerpage-container01 {
    max-width: 950px;
    align-items: flex-start;
  }
}
@media(max-width: 991px) {
  .careerpage-text {
    font-size: 16px;
  }
  .careerpage-text01 {
    font-size: 12px;
  }
  .careerpage-text02 {
    font-size: 14px;
  }
  .careerpage-text03 {
    font-size: 14px;
  }
  .careerpage-text04 {
    font-size: 14px;
  }
  .careerpage-text05 {
    font-size: 14px;
  }
  .careerpage-text06 {
    font-size: 14px;
  }
  .careerpage-text07 {
    font-size: 14px;
  }
  .careerpage-container06 {
    margin-bottom: var(--dl-space-space-halfunit);
  }
  .careerpage-text08 {
    font-size: 12px;
  }
  .careerpage-text09 {
    font-size: 14px;
  }
  .careerpage-text10 {
    font-size: 14px;
  }
  .careerpage-text11 {
    font-size: 14px;
  }
  .careerpage-text12 {
    font-size: 14px;
  }
  .careerpage-button {
    font-size: 12px;
    margin-right: var(--dl-space-space-unit);
  }
  .careerpage-text13 {
    font-size: 12px;
  }
  .careerpage-icon {
    width: 15px;
    height: auto;
  }
  .careerpage-text14 {
    font-size: 18px;
  }
  .careerpage-text15 {
    font-size: 16px;
  }
  .careerpage-icon02 {
    width: 44px;
  }
  .careerpage-text16 {
    font-size: 16px;
  }
  .careerpage-icon04 {
    width: 44px;
  }
  .careerpage-text17 {
    font-size: 16px;
  }
  .careerpage-icon06 {
    width: 44px;
  }
  .careerpage-text18 {
    font-size: 16px;
  }
  .careerpage-icon08 {
    width: 44px;
  }
}
@media(max-width: 767px) {
  .careerpage-container01 {
    flex-direction: column;
  }
  .careerpage-container02 {
    width: 100%;
  }
  .careerpage-container07 {
    justify-content: center;
  }
  .careerpage-container09 {
    width: 100%;
  }
  .careerpage-text15 {
    font-size: 14px;
  }
  .careerpage-icon02 {
    width: 34px;
  }
  .careerpage-text16 {
    font-size: 14px;
  }
  .careerpage-icon04 {
    width: 34px;
  }
  .careerpage-text17 {
    font-size: 14px;
  }
  .careerpage-icon06 {
    width: 34px;
  }
  .careerpage-text18 {
    font-size: 14px;
  }
  .careerpage-icon08 {
    width: 34px;
  }
}
@media(max-width: 479px) {
  .careerpage-text02 {
    font-size: 12px;
  }
  .careerpage-text03 {
    font-size: 12px;
  }
  .careerpage-text04 {
    font-size: 12px;
  }
  .careerpage-text05 {
    font-size: 12px;
  }
  .careerpage-text06 {
    font-size: 12px;
  }
  .careerpage-text07 {
    font-size: 12px;
  }
  .careerpage-text09 {
    font-size: 12px;
  }
  .careerpage-text10 {
    font-size: 12px;
  }
  .careerpage-text11 {
    font-size: 12px;
  }
  .careerpage-text12 {
    font-size: 12px;
  }
  .careerpage-text15 {
    font-size: 12px;
  }
  .careerpage-icon02 {
    width: 24px;
  }
  .careerpage-text16 {
    font-size: 12px;
  }
  .careerpage-icon04 {
    width: 24px;
  }
  .careerpage-text17 {
    font-size: 12px;
  }
  .careerpage-icon06 {
    width: 24px;
  }
  .careerpage-text18 {
    font-size: 12px;
  }
  .careerpage-icon08 {
    width: 24px;
  }
}

  [data-thq="thq-dropdown"]:hover > [data-thq="thq-dropdown-list"] {
    display: flex;
    }
    [data-thq="thq-dropdown"]:hover > div [data-thq="thq-dropdown-arrow"] {
    transform: rotate(90deg);
    }
   
    .career-submission-form-container {
  width: 100%;
  display: flex;
  overflow: auto;

  align-items: center;
  flex-direction: column;
}
.career-submission-form-form {
  width: 100%;
  height: auto;
  display: flex;
  padding: 25px;
  max-width: 1000px;
  align-self: center;
  box-shadow: 3px 5px 10px 0px #4a4a4a;
  margin-top: 10px;
  align-items: center;
  border-radius: 30px;
  margin-bottom: 30px;
  flex-direction: row;
  justify-content: center;
}
.career-submission-form-container1 {
  flex: 0 0 auto;
  width: 100%;
  height: auto;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
.career-submission-form-container2 {
  flex: 0 0 auto;
  width: 100%;
  height: auto;
  display: flex;
  align-items: flex-start;
  justify-content: center;
}
.career-submission-form-container3 {
  flex: 0 0 auto;
  width: 50%;
  height: auto;
  display: flex;
  align-items: center;
  padding-top: var(--dl-space-space-unit);
  padding-left: var(--dl-space-space-unit);
  margin-bottom: var(--dl-space-space-twounits);
  padding-right: var(--dl-space-space-unit);
  flex-direction: column;
  padding-bottom: 0px;
}
.career-submission-form-textinput {
  width: 100%;
  height: 50px;
  outline: none;
  font-family: Poppins;
  border-width: 0px;
  border-radius: 0px;
  margin-bottom: var(--dl-space-space-twounits);
  border-bottom-width: 1px;
  border-top-right-radius: 0;
}
.career-submission-form-textinput1 {
  width: 100%;
  height: 50px;
  outline: none;
  font-family: Poppins;
  border-width: 0px;
  border-radius: 0px;
  margin-bottom: 0px;
  border-bottom-width: 1px;
  border-top-right-radius: 0;
}
.career-submission-form-container4 {
  flex: 0 0 auto;
  width: 50%;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.career-submission-form-container5 {
  flex: 0 0 auto;
  width: 100%;
  height: auto;
  display: flex;
  padding: var(--dl-space-space-unit);
  align-items: center;
  flex-direction: column;
}
.career-submission-form-textinput2 {
  width: 100%;
  height: 50px;
  outline: none;
  font-family: Poppins;
  border-width: 0px;
  border-radius: 0px;
  margin-bottom: var(--dl-space-space-twounits);
  border-bottom-width: 1px;
  border-top-right-radius: 0;
}
.career-submission-form-textinput3 {
  width: 100%;
  height: 50px;
  outline: none;
  font-family: Poppins;
  border-width: 0px;
  border-radius: 0px;
  margin-bottom: 0px;
  border-bottom-width: 1px;
  border-top-right-radius: 0;
}
.career-submission-form-container6 {
  flex: 0 0 auto;
  width: 100%;
  height: auto;
  display: flex;
  margin-top: 0px;
  align-items: center;
  padding-top: 0px;
  padding-left: var(--dl-space-space-unit);
  padding-right: var(--dl-space-space-unit);
  flex-direction: column;
  padding-bottom: var(--dl-space-space-unit);
}
.career-submission-form-select {
  width: 100%;
  height: 50px;
  outline: none;
  align-self: stretch;
  font-family: sans-serif;
  padding-top: 0px;
  border-color: var(--dl-color-gray-black);
  border-width: 0px;
  padding-left: var(--dl-space-space-unit);
  border-radius: 0px;
  margin-bottom: var(--dl-space-space-twounits);
  padding-right: var(--dl-space-space-unit);
  padding-bottom: 0px;
  border-bottom-width: 1px;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}
.career-submission-form-textarea {
  width: 100%;
  height: 136px;
  resize: none;
  outline: none;
  border-width: 0px;
  border-radius: 0px;
  margin-bottom: var(--dl-space-space-threeunits);
  border-bottom-width: 1px;
  border-top-right-radius: 0;
}
.career-submission-form-button {
  color: var(--dl-color-gray-white);
  cursor: pointer;
  font-size: 20px;
  font-style: normal;
  transition: 0.3s;
  font-family: Montserrat;
  font-weight: 700;
  padding-top: var(--dl-space-space-unit);
  border-width: 0px;
  padding-left: var(--dl-space-space-threeunits);
  border-radius: 10px;
  padding-right: var(--dl-space-space-threeunits);
  padding-bottom: var(--dl-space-space-unit);
  background-image: linear-gradient(90deg, rgb(88, 181, 107) 0.00%,rgb(31, 149, 141) 100.00%);
}
.career-submission-form-button:hover {
  background-image: linear-gradient(90deg, rgb(0, 0, 0) 0.00%,rgb(0, 0, 0) 100.00%),linear-gradient(90deg, rgb(88, 181, 107) 0.00%,rgb(31, 149, 141) 100.00%);
}
@media(max-width: 1600px) {
  .career-submission-form-form {
    align-items: center;
    margin-bottom: 0px;
    justify-content: center;
  }
  .career-submission-form-textinput {
    font-size: 15px;
  }
  .career-submission-form-textinput1 {
    font-size: 15px;
  }
  .career-submission-form-textinput2 {
    font-size: 15px;
  }
  .career-submission-form-textinput3 {
    font-size: 15px;
  }
  .career-submission-form-textarea {
    font-size: 15px;
  }
  .career-submission-form-button {
    font-size: 15px;
  }
}
@media(max-width: 1200px) {
  .career-submission-form-form {
    max-width: 800px;
  }
  .career-submission-form-container2 {
    height: auto;
  }
  .career-submission-form-container3 {
    margin-bottom: 0px;
  }
  .career-submission-form-textinput {
    font-size: 14px;
  }
  .career-submission-form-textinput1 {
    font-size: 14px;
    margin-bottom: var(--dl-space-space-unit);
  }
  .career-submission-form-container4 {
    width: 50%;
    height: auto;
  }
  .career-submission-form-container5 {
    width: 100%;
  }
  .career-submission-form-textinput2 {
    font-size: 14px;
  }
  .career-submission-form-textinput3 {
    font-size: 14px;
    margin-bottom: var(--dl-space-space-unit);
  }
  .career-submission-form-select {
    margin-bottom: var(--dl-space-space-twounits);
  }
  .career-submission-form-textarea {
    font-size: 14px;
  }
  .career-submission-form-button {
    font-size: 14px;
  }
}
@media(max-width: 991px) {
  .career-submission-form-textinput {
    font-size: 13px;
  }
  .career-submission-form-textinput1 {
    font-size: 13px;
  }
  .career-submission-form-textinput2 {
    font-size: 13px;
  }
  .career-submission-form-textinput3 {
    font-size: 13px;
  }
  .career-submission-form-textarea {
    font-size: 13px;
  }
  .career-submission-form-button {
    font-size: 13px;
  }
}
@media(max-width: 767px) {
  .career-submission-form-form {
    max-width: 700px;
  }
  .career-submission-form-container2 {
    flex-direction: column;
  }
  .career-submission-form-container3 {
    width: 100%;
    align-items: center;
  }
  .career-submission-form-textinput {
    font-size: 12px;
  }
  .career-submission-form-textinput1 {
    font-size: 12px;
  }
  .career-submission-form-container4 {
    width: 100%;
  }
  .career-submission-form-container5 {
    width: 100%;
    display: block;
    padding-top: var(--dl-space-space-halfunit);
    margin-bottom: var(--dl-space-space-twounits);
    padding-bottom: 0px;
  }
  .career-submission-form-textinput2 {
    font-size: 12px;
  }
  .career-submission-form-textinput3 {
    font-size: 12px;
    margin-bottom: 0px;
  }
  .career-submission-form-select {
    margin-bottom: var(--dl-space-space-twounits);
  }
  .career-submission-form-textarea {
    font-size: 12px;
  }
  .career-submission-form-button {
    font-size: 12px;
  }
}
@media(max-width: 479px) {
  .career-submission-form-form {
    max-width: 400px;
  }
  .career-submission-form-container3 {
    margin-bottom: var(--dl-space-space-twounits);
  }
  .career-submission-form-textinput {
    font-size: 10px;
  }
  .career-submission-form-textinput1 {
    font-size: 10px;
  }
  .career-submission-form-container5 {
    padding-top: 0px;
  }
  .career-submission-form-textinput2 {
    font-size: 10px;
  }
  .career-submission-form-textinput3 {
    font-size: 10px;
  }
  .career-submission-form-textarea {
    font-size: 10px;
  }
  .career-submission-form-button {
    font-size: 10px;
  }
}
